<template>
<div class="table--header-section">
    <SearchBarComponent @handleSearch="handleSearch" />
    <div>
        <Multiselect v-model="actionField" :options="[{
          name: 'metti in evidenza', $isDisabled: itemsSelected.filter(val => val.status === 'DRAFT').length ? true : false}, { name: 'rimuovi da evidenza' } ]" :searchable="false" :show-labels="false" :resetAfter="true" :hideSelected="true" placeholder="Seleziona un'azione" track-by="name" label="name" :disabled="itemsSelected.length ? false : true"></Multiselect>
    </div>
</div>
<EasyDataTable v-model:items-selected="itemsSelected" v-model:server-options="serverOptions" :server-items-length="props.totalLength" :headers="headers" :items="tableDisplayData" :header-item-class-name="headerItemClassNameFunction" :body-row-class-name="bodyRowClassNameFunction" :body-item-class-name="bodyItemClassNameFunction" :loading="isLoading" alternating buttons-pagination @click-row="showRow">
    <template #item-name="{ Avatar, name, ALT, ID }">
        <div class="table-col-item">
            <img class="avator" :src="Avatar" :alt="ALT" />
            <div class="text-16px ml-12px">{{ name }}</div>
            <div class="table-uuid">{{ ID }}</div>
        </div>
    </template>
    <template #item-status="{ status }">
        <div class="table-col-item" :class="status === 'DRAFT' && 'evidence-disable' ">
            <StatusComponent :text="status" :type="status === 'PUBLISHED' ? 'primary' : 'disable'" />
        </div>
    </template>
    <template #item-marked="{ marked }">
        <div class="table-col-item">
            <StatusComponent :text="marked" :type="marked === 'In evidenza' ? 'primary' : 'disable'" />
        </div>
    </template>
</EasyDataTable>
</template>

<script>
import SearchBarComponent from './SearchBar.vue';
import StatusComponent from './Status.vue';
import Multiselect from 'vue-multiselect';
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import {
    ref,
    watch,
    toRef
} from 'vue';

export default {
    name: "PremialitaTable",
    components: {
        SearchBarComponent,
        StatusComponent,
        Multiselect,
        EasyDataTable: Vue3EasyDataTable
    },
    props: {
        activitiesData: {
            type: Object
        },
        totalLength: {
            type: Number
        },
        loading: {
            type: Boolean
        }
    },
    emits: ['handleEdit', 'handleAction', 'fetchData', 'tableSearch'],
    setup(props, {
        emit
    }) {
        const tableDisplayData = toRef(props, 'activitiesData');
        const actionField = ref(null);
        const itemsSelected = ref([]);
        const isLoading = toRef(props, 'loading');

        const serverOptions = ref({
            page: 1,
            rowsPerPage: 15,
            sortBy: 'name',
            sortType: 'desc',
        });

        const headers = [{
                text: "NOME",
                value: "name",
                sortable: true
            },
            {
                text: "RAGGRUPPAMENTO",
                value: "groupset",
                sortable: true
            },
            {
                text: "PUNTI",
                value: "points",
                sortable: true
            },
            {
                text: "ULTIMA MODIFICA",
                value: "updatedAt",
                sortable: true
            },
            {
                text: "STATUS",
                value: "status",
                sortable: true
            },
            {
                text: "IN EVIDENZA",
                value: "marked",
                sortable: true
            },
        ];

        const handleSearch = (payload) => {
            emit('tableSearch', payload.value)
        }

        const showRow = (payload) => {
            emit('handleEdit', payload);
        }

        const bodyRowClassNameFunction = (item) => {
            if (item.score < 60) return 'fail-row';
            return 'pass-row';
        };

        const bodyItemClassNameFunction = (column) => {
            if (column === 'score') return 'score-column';
            return '';
        };

        const headerItemClassNameFunction = (header) => {
            if (header.value === 'score') return 'score-column';
            return '';
        };

        watch(serverOptions, (value) => {
            emit('fetchData', value);
        }, {
            deep: true
        })

        watch(actionField, (newValue) => {
            if (newValue === null) return false;
            let temp = [];
            if (itemsSelected.value.length) {
                if (newValue.name === 'metti in evidenza') {
                    temp = itemsSelected.value.filter(val => val.marked === 'Non in evidenza');
                } else {
                    temp = itemsSelected.value.filter(val => val.marked === 'In evidenza');
                }

                if (temp.length) {
                    emit('handleAction', {
                        action: newValue.name,
                        data: temp
                    });
                }
            }
        })

        return {
            props,
            actionField,
            tableDisplayData,
            itemsSelected,
            headers,
            serverOptions,
            isLoading,
            handleSearch,
            showRow,
            bodyRowClassNameFunction,
            bodyItemClassNameFunction,
            headerItemClassNameFunction
        }
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style><style>
.table--header-section {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
    margin: 24px 0;
    flex-shrink: 0;
    gap: 16px;
}

.table--header-section .multiselect__tags {
    border-radius: 4px;
    border: 1px solid #1A1A1A;
    color: #1A1A1A;
    width: 224px;
    box-shadow: 0px 0px 4px 0px #0000002E;
}

.table-uuid {
    display: none;
}

.table-col-item {
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 0 12px;
}

table tbody td:nth-child(4) {
    text-align: center !important;
}

table img {
    width: 38px;
    height: 38px;
    border-radius: 100%;
}

.custom-icon {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 30px;
    background: url('@/assets/icons/expand.png') no-repeat right center;
}

.easy-checkbox {
    width: 50px;
}

.vue3-easy-data-table__header>tr:first-child {
    width: 50px;
}

.pagination__rows-per-page {
    display: none !important;
}
</style>
