<!-- eslint-disable vue/multi-word-component-names -->
<template>
<button v-if="props.type == 'remove'" @click="handleClick" class="px-3 py-12px border-size-button border-remove-color font-remove-color button-style bg-remove">
    {{
          props.text
        }}
    <span>
        <img class="ml-2 d-inline-block" src="@/assets/icons/delete.png" />
    </span>
</button>
<button v-else class="px-4 py-12px border-size-button button-style" :class="
          [
          props.type === 'regular' ? (
            props.isDisable ? 
              'border-disabled-color font-disabled-color' : 
              'border-regular-color font-regular-color'
          ) : (
            props.isDisable ? 
              'border-disabled-color font-disabled-color' :
              'border-primary-color font-primary-color'
          ),
          props.class
        ]
        " :disabled="props.isDisable" @click="handleClick">
    {{ props.text }}
</button>
</template>

    
<script>
export default {
    name: 'ButtonComponent',
    props: {
        text: {
            type: String
        },
        type: {
            type: String
        },
        isDisable: {
            type: Boolean,
            default: false
        },
        class: {
            type: String
        }
    },
    emits: ["handleClick"],
    setup(props, {
        emit
    }) {
        const handleClick = () => {
            emit('handleClick', props.text);
        }
        return {
            props,
            handleClick
        }
    }
}
</script>

    
<style>
.py-12px {
    padding-top: 12px;
    padding-bottom: 12px;
}

.border-size-button {
    border-radius: 4px;
}

.button-style {
    font-family: Titillium Web;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.bg-red {
    background-color: #CC334D;
}

.bg-red:hover {
    background-color: #A62F47;
}

.border-red {
    border: 1px solid #CC334D !important;
}

.font-regular-color {
    color: var(--accent-blu-accent, #2D489D);
}

.border-regular-color {
    border: 1px solid var(--accent-blu-accent, #2D489D);
}

.border-regular-color:hover {
    background-color: #EBEBEB !important;
}

.border-regular-color:active {
    color: #192857 !important;
}

.font-primary-color {
    color: var(--neutrali-bianco, #FFF);
}

.border-primary-color {
    border-color: #2D489D;
}

.font-diabled-color {
    color: #8F8F8F;
}

.border-regular-color:hover {
    background-color: #EBEBEB;
}

.bg-disabled {
    background: #EBEBEB;
}

.bg-primary-custom {
    background: var(--accent-blu-accent, #2D489D);
}

.bg-primary-custom:hover {
    background-color: #192857;
}

.font-disabled-color {
    color: #8F8F8F;
}

.border-disabled-color {
    border: 1px solid var(--color-background-disabled, #EBEBEB);
}

.border-disabled-color:hover {
    background-color: #D9DADB;
}

.border-remove-color {
    border: 1px solid var(--status-alert-rosso-alert, #CC334D);
}

.bg-remove {
    background: var(--neutrali-grigio-chiaro, #EBEBEB);
}

.font-remove-color {
    color: var(--status-alert-rosso-alert, #CC334D);
}

.bg-remove:hover {
    background: var(--neutrali-bianco, #FFF);
}
</style>
