<template>
<div class="cropper-slider-navigation">
    <div class="cropper-slider-navigation__wrapper">
        <div class="cropper-slider-navigation__zoom-icon--left">
            <span class="material-symbols-outlined pushed-right">
                <img src="@/assets/icons/zoom_out.png" alt="zoom-out">
            </span>
        </div>
        <div class="cropper-slider-navigation__line-wrapper" ref="line" @mousedown="onStart" @touchstart="onStart">
            <div class="cropper-slider-navigation__line">
                <div class="cropper-slider-navigation__fill" :style="{flexGrow: props.zoom}"></div>
                <div class="cropper-slider-navigation__circle" :class="{'cropper-slider-navigation__circle--focus': focus}" :style="{left: `${zoom * 100}%`}">
                    <div class="cropper-slider-navigation__inner-circle" :class="{'cropper-slider-navigation__inner-circle--focus': focus}"></div>
                </div>
            </div>
        </div>

        <div class="cropper-slider-navigation__zoom-icon--right">
            <span class="material-symbols-outlined pushed-right">
                <img src="@/assets/icons/zoom_in.png" alt="zoom-in">
            </span>
        </div>
    </div>
</div>
</template>

  
<script>
import {
    ref,
    onMounted,
    onUnmounted
} from 'vue';

export default {
    name: "UiImageSlider",
    props: {
        zoom: {
            type: Number,
            default: 0
        }
    },
    emits: ['change'],
    setup(props, {
        emit
    }) {
        const focus = ref(false);

        const onDrag = (e) => {
            if (focus.value) {

                const position = e.touches ? e.touches[0].clientX : e.clientX;
                const line = document.querySelector('.cropper-slider-navigation__line-wrapper');

                if (line) {
                    const {
                        left,
                        width
                    } = line.getBoundingClientRect();
                    emit('change', Math.min(1, Math.max(0, position - left) / width))

                }
                if (e.preventDefault) {
                    e.preventDefault();
                }
            }
        };

        const onStop = () => {
            focus.value = false;
        };

        const onStart = (e) => {
            focus.value = true;
            onDrag(e);
        };

        onMounted(() => {
            window.addEventListener('mouseup', onStop, {
                passive: false
            });
            window.addEventListener('mousemove', onDrag, {
                passive: false
            });
            window.addEventListener('touchmove', onDrag, {
                passive: false
            });
            window.addEventListener('touchend', onStop, {
                passive: false
            });
        });

        onUnmounted(() => {
            window.removeEventListener('mouseup', onStop);
            window.removeEventListener('mousemove', onDrag);
            window.removeEventListener('touchmove', onDrag);
            window.removeEventListener('touchend', onStop);
        });
        return {
            props,
            focus,
            onStart
        }
    }

}
</script>

  
<style lang="scss">
.cropper-slider-navigation {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 50px;

    &__wrapper {
        display: flex;
        align-items: center;
        max-width: 400px;
        width: 100%;
    }

    &__zoom-icon {
        height: 18.75px;
        width: 18.75px;
        fill: rgb(101, 119, 134);
        flex-shrink: 0;

        &--left {
            margin-right: 10px;

            img {
                width: 30px;
                height: 30px;
            }
        }

        &--right {
            margin-left: 10px;

            img {
                width: 30px;
                height: 30px;
            }
        }
    }

    &__line-wrapper {
        width: 100%;
        height: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;
    }

    &__line {
        background: gray;
        height: 3px;
        width: 100%;
        border-radius: 5px;
        display: flex;
        position: relative;
        align-items: center;
    }

    &__fill {
        background: blue;
        align-self: stretch;
        flex-basis: auto;
        flex-direction: column;
        flex-shrink: 0;
    }

    &__circle {
        width: 18px;
        height: 18px;
        margin-left: -15px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        transition-duration: 0.2s;
        transition-property: background-color, box-shadow;
        background-color: transparent;

        &:hover {
            background-color: #192857;
        }

        &--focus {
            background-color: #192857;
        }
    }

    &__inner-circle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: blue;
        transform: scale(1);
        transition-duration: 0.1s;
        transition-property: transform;

        &--focus {
            transform: scale(1.2);
        }
    }
}
</style>
