<template>
<draggable :list="evidenceDisplay" class="d-flex flex-column mt-1 p-3 gap-16px bg-evidence-list" handle=".handle">
    <div v-for="(element, index) in evidenceDisplay" :key="element.ID" class="d-flex flex-row align-items-center">
        <div class="d-flex flex-row flex-1 align-items-center gap-24px">
            <div class="mx-2">{{ index + 1 }}.</div>
            <div class="d-flex flex-row align-items-center gap-12px">
                <img class="evidence-avatar" :src="`${props.baseUrl}/v1/public/image-files/${element.Avatar}?size=thumb`" :alt="element.ALT" />
                <div class="evidence-name-list">
                    {{ element.name }}
                </div>
            </div>
        </div>
        <i class="handle drag-img">
            <img src="@/assets/icons/drag.png" />
        </i>
    </div>
</draggable>
</template>

<script>
import {
    toRef,
} from 'vue';
import {
    VueDraggableNext
} from 'vue-draggable-next'

export default {
    name: 'PremialitaEvidenceList',
    components: {
        draggable: VueDraggableNext
    },
    props: {
        evidenceData: {
            type: Array
        },
        baseUrl: {
            type: String
        }
    },
    setup(props) {
        const evidenceDisplay = toRef(props, 'evidenceData');

        return {
            evidenceDisplay,
            props,
        }
    }
}
</script>

<style>
.gap-12px {
    gap: 12px;
}

.evidence-name-list {
    color: var(--neutrali-nero, #1A1A1A);
    font-family: Titillium Web;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 112.5% */
}

.bg-evidence-list {
    border-radius: 8px;
    background: var(--neutrali-grigio-chiarissimo, #FAFAFA);
}

.bg-evidence-list .evidence-avatar {
    width: 38px;
    height: 38px;
    border-radius: 100%;
}

i.drag-img:hover {
    cursor: grab;
}

.drag-img>img {
    width: 24px !important;
    height: 24px !important;
}

ul.bg-evidence-list>li:hover {
    background-color: #EAEAEA;
}
</style>
