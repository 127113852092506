<template>
<div class="modal fade show d-block" tabindex="-1" role="dialog" raria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmeModalLongTitle">{{ props.modalTitle }}</h5>
            </div>
            <div class="modal-body">
                <div v-html="props.modalContent"></div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" @click="handleModal">Conferma</button>
                <button type="button" class="btn btn-secondary" @click="hideModal">Annulla</button>
            </div>
        </div>
    </div>
</div>
</template>

  
<script>
import {
    closeModal
} from 'jenesius-vue-modal'
export default ({
    name: "DelteModal",
    props: {
        modalTitle: {
            type: String,
        },
        modalContent: {
            type: String
        }
    },
    emits: ['ModalConfirm'],
    setup(props, {
        emit
    }) {
        const hideModal = () => {
            closeModal()
        }

        const handleModal = () => {
            emit('ModalConfirm')
        }
        return {
            props,
            handleModal,
            hideModal
        }
    }
})
</script>
