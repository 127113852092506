<template>
<div class="p-3">
    <button class="d-flex flex-row gx-4px border-0 bg-white" @click="handlePageModal">
        <img src="@/assets/icons/arrow-back.png" />
        <div class="arrow-button">INDIETRO</div>
    </button>

    <div class="d-flex flex-col w-100">
        <div class="d-flex flex-row w-100">
            <h1 class="text-title">Premialità Offerta</h1>
            <div class="ml-auto d-flex flex-row gap-16px">
                <premialita-button text="Salva bozza" type="regular" class="bg-white" :isDisable="false" @handleClick="handleDraft" />
                <premialita-button text="Pubblica offerta" type="disabled" class="bg-disabled" :isDisable="true" />
            </div>
        </div>
    </div>

    <div class="mt-48px d-flex flex-row gap-16px ">
        <div class="create-route-panel-body flex-1 ">
            <div class="d-flex flex-row gap-16px w-100">
                <premialita-input v-if="langCode === 'it-IT'" title="Titolo" :max-number="50" :text="textVar[0].value" placeholder="Inserisci un titolo" language="it-IT" @handleText="handleTitle" />
                <premialita-input v-else title="Title" :max-number="50" :text="textVar[3].value" placeholder="Enter a title" language="en-EN" @handleText="handleTitle" />
                <premialita-input v-if="langCode === 'it-IT'" title="Sottotitolo" :max-number="50" :text="textVar[1].value" placeholder="Inserisci un sottotitolo" language="it-IT" @handleText="handleSubTitle" />
                <premialita-input v-else title="Subtitle" :max-number="50" :text="textVar[4].value" placeholder="Enter a subtitle" language="en-EN" @handleText="handleSubTitle" />
            </div>
            <div class="d-flex flex-row gap-16px w-100">
                <div class="flex-1">
                    <premialita-text-area v-if="langCode === 'it-IT'" title="Descrizione" :max-number="2000" :rows="8" :placeholder="'Inserisci una descrizione'" language="it-IT" :text="textVar[2].value" @handleText="handleDescription" />
                    <premialita-text-area v-else title="Description" :max-number="2000" :rows="8" :placeholder="'Enter a description'" language="en-EN" :text="textVar[5].value" @handleText="handleDescription" />
                </div>
                <div class="flex-1">
                    <premialita-image-upload :token="props.token" :baseUrl="props.baseUrl" @handleUpload="handleUpload" />
                </div>
            </div>
            <div class="w-100">
                <div class="title-input mb-2">Tipo di offerta</div>
                <div class="d-flex flex-row gap-8px align-items-center justify-content-between">
                    <div class="offer-type-select w-50">
                        <Multiselect :searchable="false" :show-labels="false" :allow-empty="false" track-by="name" label="name" placeholder="Seleziona il tipo" v-model="selectedOfferType" :options="offerTypes"></Multiselect>
                    </div>
                    <div class="mr-8" v-if="selectedOfferType?.name === 'Sconto su biglietto'">
                        <PointComponent title="Valore percentuale" type="discount" v-model:points="discountValue" :maxLength="100" />
                    </div>
                </div>
            </div>
            <div class="w-100 d-flex flex-row gap-8px align-items-center justify-content-between">
                <div class="w-50">
                    <div class="title-input">
                        Seleziona il raggruppamento inerente all'offerta
                    </div>
                    <div>
                        <Multiselect :searchable="false" :show-labels="false" :allow-empty="false" label="name" v-model="groupField" :options="groupsetData" :hideSelected="true" track-by="name" placeholder="Seleziona gruppo"></Multiselect>
                    </div>
                </div>
                <div class="mr-8">
                    <PointComponent title="Numero di punti richiesti" type="point" v-model:points="pointsNo" :maxLength="2000" />
                </div>
            </div>

            <div v-if="isLoading" class="d-flex flex-row w-50">
                <Loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" color="#2D489D" loader="dots"></Loading>
            </div>
            <div v-show="!isLoading" class="d-flex flex-row gap-16px w-100">
                <div class="w-50">
                    <premialita-search-item @selectedMusuemFunc="selectedMusuemFunc" :museumData="museumsData" :baseUrl="props.baseUrl" />
                </div>
                <div class="w-50 px-3">
                    <premialita-museum-list :selectedList="selectMuseums" @onMuseumOrderChange="onMuseumOrderChange" />
                </div>
            </div>
        </div>
        <div>
            <premialita-language @handleLangCode="handleLangCode" @handleDel="handleDel" />
        </div>
    </div>
    <premialita-modal :isOpen="isValidateModal">
        <template #header>
            <h1 class="font-display-4">Avvertimento</h1>
        </template>
        <template #content>
            <h5>Per salvare l'offerta è necessario compilare i seguenti campi:</h5>
            <div v-for="(item, index) in validateText" :key="index" v-html="item" class="px-3"></div>
        </template>
        <template #footer>
            <premialita-button text="Conferma" type="primary" class="bg-primary-custom" :isDisable="false" @handleClick="handleValidateModal" />
        </template>
    </premialita-modal>
</div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import PointComponent from "@/components/PointComponent.vue";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
import {
    ref,
    toRef,
    watch
} from 'vue';
export default {
    name: 'AddOffer',
    components: {
        Multiselect,
        PointComponent,
        Loading
    },
    props: {
        token: {
            type: String,
            required: true
        },
        baseUrl: {
            type: String,
            required: true
        },
        getGroupset: {
            type: Object
        }
    },
    emits: ['handlePageModal', 'handleReload'],
    setup(props, {
        emit
    }) {
        const isLoading = ref(false);
        const getError = ref(null);
        const langCode = ref('it-IT');
        const offerTypes = [{
            name: 'Sconto su biglietto',
            value: 'percentage'
        }, {
            name: 'Ingresso gratuito',
            value: 'flat'
        }];
        const selectedOfferType = ref(null);
        const imagesData = ref([]);
        const validateText = ref([]);
        const isValidateModal = ref(false);
        const selectMuseums = ref([]);
        const museumsData = ref([]);
        const groupsetData = toRef(props, 'getGroupset');
        const groupField = ref(null);
        const discountValue = ref(10);
        const pointsNo = ref(1);

        const textVar = ref([{
                key: 'labels.title',
                value: '',
                languageCode: 'it-IT'
            },
            {
                key: 'labels.subtitle',
                value: '',
                languageCode: 'it-IT'
            },
            {
                key: 'labels.description',
                value: '',
                languageCode: 'it-IT'
            },
            {
                key: 'labels.title',
                value: '',
                languageCode: 'en-EN'
            },
            {
                key: 'labels.subtitle',
                value: '',
                languageCode: 'en-EN'
            },
            {
                key: 'labels.description',
                value: '',
                languageCode: 'en-EN'
            },
        ]);

        const fetchMuseums = async (groupset) => {
            isLoading.value = true;
            try {
                const res = await axios.get(`${props.baseUrl}/v1/private/museums`, {
                    params: {
                        groupsetId: groupset.uuid
                    },
                    headers: {
                        Authorization: `Bearer ${props.token}`
                    }
                });
                getError.value = null;
                museumsData.value = res.data.map(val => ({
                    ...val,
                    flag: false
                })).sort((a, b) => {
                    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });
            } catch (err) {
                getError.value = err;
            } finally {
                isLoading.value = false
            }
        }

        const handleTitle = (payload) => {
            payload.lang === 'it-IT' ? textVar.value[0] = {
                ...textVar.value[0],
                value: payload.text
            } : textVar.value[3] = {
                ...textVar.value[3],
                value: payload.text
            }
        }

        const handleSubTitle = (payload) => {
            payload.lang === 'it-IT' ? textVar.value[1] = {
                ...textVar.value[1],
                value: payload.text
            } : textVar.value[4] = {
                ...textVar.value[4],
                value: payload.text
            }
        }

        const handleDescription = (payload) => {
            payload.lang === 'it-IT' ? textVar.value[2] = {
                ...textVar.value[2],
                value: payload.text
            } : textVar.value[5] = {
                ...textVar.value[5],
                value: payload.text
            }
        }

        const handleUpload = (payload) => {
            imagesData.value = payload;
        }

        const isEmpty = (str) => {
            return (str === null || str === undefined || str.length <= 0) ? true : false;
        }

        const handleDraft = async () => {
            if (
                !isEmpty(textVar.value[0].value) && !isEmpty(textVar.value[2].value) && !isEmpty(textVar.value[3].value) &&
                !isEmpty(textVar.value[5].value) && !isEmpty(imagesData.value) && selectMuseums.value.length >= 2 && !isEmpty(selectedOfferType.value)
            ) {
                isLoading.value = true;
                try {
                    await axios.
                    post(
                        `${props.baseUrl}/v1/private/offers`, {
                            status: 'DRAFT',
                            marked: false,
                            museums: [...selectMuseums.value].map(val => val.uuid),
                            images: [...imagesData.value].map(val => val.uuid),
                            name: textVar.value[0].value,
                            groupsetId: groupField.value.uuid,
                            textFields: [...textVar.value].map(val => ({
                                ...val
                            })),
                            type: selectedOfferType.value?.value,
                            points: pointsNo.value,
                            discount: selectedOfferType.value?.name === "Ingresso gratuito" ? 0 : discountValue.value,
                            // expiresAt: "2024-03-14T09:23:54.443Z",
                        }, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${props.token}`,
                            },
                        }
                    )
                    handlePageModal();
                } catch (err) {
                    console.log('Add-Offer-Res-->>', err)
                } finally {
                    isLoading.value = false;
                    emit('handleReload');
                }

                return true;
            }
            validateText.value = [];
            if (isEmpty(textVar.value[0].value)) validateText.value.push('<b>Titolo in Italiano</b>');
            if (isEmpty(textVar.value[2].value)) validateText.value.push('<b>Descrizione in Italiano</b>');
            if (isEmpty(textVar.value[3].value)) validateText.value.push('<b>Titolo in Inglese</b>');
            if (isEmpty(textVar.value[5].value)) validateText.value.push('<b>Descrizione in Inglese</b>');
            if (isEmpty(imagesData.value)) validateText.value.push('<b>Immagini</b>');
            if (isEmpty(selectedOfferType.value)) validateText.value.push('<b>Tipo di offerta</b>');
            if (selectMuseums.value.length < 2) validateText.value.push('<b>Musei</b>');
            isValidateModal.value = true;
        }

        const selectedMusuemFunc = (selectedMuseums) => {
            selectMuseums.value = [...selectedMuseums.value].map(val => ({
                ...val
            }));
        }

        const onMuseumOrderChange = (payload) => {
            selectMuseums.value = payload;
        }

        const handlePageModal = () => {
            emit('handlePageModal')
        }

        const handleValidateModal = () => {
            isValidateModal.value = false;
        }

        const handleLangCode = (payload) => {
            langCode.value = payload === 'Italiano' ? 'it-IT' : 'en-EN';
        }

        const handleDel = () => {}

        const handleDiscountValue = (payload) => {
            discountValue.value = payload;
        }

        const handlePointsNo = (payload) => {
            pointsNo.value = payload;
        }

        watch(groupField, newValue => {
            fetchMuseums(newValue);
            selectMuseums.value = [];
        })

        return {
            props,
            isLoading,
            getError,
            langCode,
            textVar,
            isValidateModal,
            validateText,
            offerTypes,
            selectedOfferType,
            selectMuseums,
            museumsData,
            discountValue,
            groupsetData,
            groupField,
            pointsNo,
            handleDraft,
            handlePageModal,
            handleTitle,
            handleSubTitle,
            handleDescription,
            handleUpload,
            handleLangCode,
            handleDel,
            handleValidateModal,
            selectedMusuemFunc,
            onMuseumOrderChange,
            handleDiscountValue,
            handlePointsNo
        }
    }
}
</script>

<style>
.gx-4px {
    gap: 4px;
}

.arrow-button {
    color: var(--neutrali-grigio-scuro, #4C4C4C);
    /* caption */
    font-family: Titillium Web;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 171.429% */
}

.create-route-panel-body {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    align-content: flex-start;
    flex-shrink: 0;
    flex-wrap: wrap;
    gap: 32px;
    border-radius: 8px;
    border: 1px solid var(--neutrali-grigio-segnaposto, #8F8F8F);
}

p.text-overview {
    text-align: left;
}

.flex-1 {
    flex: 1;
}

img {
    display: block;
}

.label-left {
    padding-right: 0 !important;
}

.label-right {
    padding-left: 0 !important;
}

.ml-auto {
    margin-left: auto !important;
}

.point-section {
    padding: 48px;
}

.w-50 {
    width: 50%;
}

.gap-64px {
    gap: 156px;
}

div,
p {
    user-select: none;
}

@media screen and (min-width: 1420px) {
    .offer-type-select {
        width: 456px;
    }
}
</style>
