<template>
<div class="search-bar-container">
    <img src="@/assets/icons/search.png" @click="handleSearchStr" />
    <input class="search-text" v-model="searchStr" placeholder="Cerca..." @keyup.enter="handleSearchStr" />
</div>
</template>

<script>
import {
    ref,
} from 'vue'
export default {
    name: 'SearchBarComponent',
    emits: ['handleSearch'],
    setup(props, {
        emit
    }) {
        const searchStr = ref('');
        const handleSearchStr = () => {
            emit('handleSearch', searchStr);
        }

        return {
            searchStr,
            handleSearchStr
        }
    }
}
</script>

<style>
.search-bar-container {
    display: flex;
    padding: 0.5rem;
    flex-flow: row;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid var(--neutrali-nero, #1A1A1A);
    background: #FFF;
    min-width: 264px;
    width: 264px;
    gap: 8px;
    box-shadow: 0px 0px 4px 0px #0000002E;
}

.search-bar-container>.search-text {
    color: var(--neutrali-grigio-segnaposto, #8F8F8F);
    font-family: Titillium Web;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    width: 100%;
    border: none;
}

input:focus {
    outline: none;
}
</style>
