<template>
<!-- <div v-if="isModal === 'add'">
    <AddRouteView @handleModal="handleModal" :token="props.token" :baseUrl="props.baseUrl" :totals="totals" :published="published" :userRole="userRole" />
</div> -->
<!-- <div v-else-if="isModal === 'edit'">
    <EditRouteView @handleModal="handleModal" :token="props.token" :baseUrl="props.baseUrl" :editData="editData" :totals="totals" :published="published" :userRole="userRole" />
</div> -->
<div class="p-container">
    <div class="d-flex flex-col w-100">
        <div class="d-flex flex-row w-100">
            <h1 class="text-title">Itinerari consigliati</h1>
            <div v-if="activeItem === 'home'" class="ml-auto d-flex flex-row gap-16px">
                <ButtonComponent text="Esporta lista" type="regular" class="bg-white" :isDisable="false" @handleClick="handleExportModal" />
                <ButtonComponent text="Aggiungi itinerario" type="primary" class="bg-primary-custom" :isDisable="isGroupsetModal && false" @handleClick="addRouteAndNavigate" />
            </div>
            <div v-else class="ml-auto d-flex flex-row gap-16px">
                <ButtonComponent text="Salva ordinamento" type="primary" class="bg-primary-custom" :isDisable="false" @handleClick="saveOrder" />
            </div>
        </div>
    </div>

    <widget-container-modal />

    <div>
        <p v-if="isLoading" class="text-overview">
            <Loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" color="#2D489D" loader="dots"></Loading>
        </p>
        <p v-else-if="getError" class="text-overview">No data to display</p>
        <p v-else class="text-overview">{{ published }} itinerari pubblicati su {{ totals }} presenti</p>
    </div>

    <ul class="nav nav-tabs">
        <li class="nav-item">
            <a class="nav-link" @click.prevent="setActive('home')" :class="{ active: isActive('home') }" href="#home">Home</a>
        </li>
        <li v-if="userRole" class="nav-item">
            <a class="nav-link" @click.prevent="setActive('inevidence')" :class="{ active: isActive('inevidence') }" href="#inevidence">Ordina itinerari in evidenza</a>
        </li>
    </ul>
    <div class="tab-content py-3" id="myTabContent">
        <div class="tab-pane fade" :class="{ 'active show': isActive('home') }" id="home">
            <div v-if="isLoading">Loading...</div>
            <div v-else-if="getError">No data to display</div>
            <div v-else>
                <TableComponent :baseUrl="props.baseUrl" :token="props.token" :userRole="userRole" :totals="totals" @handleEdit="handleEdit" />
            </div>
        </div>
        <div class="tab-pane fade" :class="{ 'active show': isActive('inevidence') }" id="inevidence">
            <div v-if="isLoading">Loading...</div>
            <div v-else-if="getError">No data to display</div>
            <div v-else>
                <InEvidenceList :baseUrl="props.baseUrl" :token="props.token" @handleOrder="handleOrder" />
            </div>
        </div>
    </div>
</div>
<BaseModal :isOpen="isGroupsetModal" name="warning-modal">
    <template #header>
        <h1 class="font-display-4">
            Avvertimento!
        </h1>
    </template>
    <template #content>
        <h5>Per poter accedere alla sezione "itinerari" è necessario essere profilati come "Responsabile del raggruppamento" o "supporto al responsabile" nella piattaforma del Sistema museale nazionale.</h5>
    </template>
    <template #footer>
        <ButtonComponent text="Indietro" type="regular" class="bg-white" :isDisable="false" @handleClick="warningGoback" />
    </template>
</BaseModal>

<BaseModal :isOpen="isError" @modal-close="() => ''" name="warning-modal">
    <template #header>
        <h3>
            Avvertimento!
        </h3>
    </template>
    <template #content>
        <h5>Non possiamo recuperare i dati per il tuo itinerario di viaggio.</h5>
    </template>
    <template #footer>
        <ButtonComponent text="Indietro" type="regular" class="bg-white" :isDisable="false" @handleClick="warningGoback" />
    </template>
</BaseModal>
</template>

<script>
import ButtonComponent from '../components/Button.vue';
import TableComponent from '../components/Table.vue';
import AddRouteView from './addRoute.vue';
import EditRouteView from './editRoute.vue';
import InEvidenceList from '../components/InEvidenceList.vue';
import SelectModal from '../components/SelectModal.vue';
import BaseModal from '@/components/BaseModal.vue';
import {
    useToast
} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import axios from 'axios';
import {
    mkConfig,
    generateCsv,
    download
} from "export-to-csv";
import {
    ref,
    onMounted,
    onBeforeMount
} from 'vue'
import {
    container,
    openModal
} from "jenesius-vue-modal";
import {
    version
} from "@/../package.json";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
    name: 'RoutesView',
    components: {
        ButtonComponent,
        TableComponent,
        InEvidenceList,
        BaseModal,
        Loading,
        WidgetContainerModal: container
    },
    props: {
        token: {
            type: String,
        },
        baseUrl: {
            type: String
        },
        router: {
            type: Object
        },
        route: {
            type: Object
        }
    },
    setup(props) {
        const totals = ref(0);
        const published = ref(0);
        const isLoading = ref(false);
        const getError = ref(null);
        const isModal = ref('');
        const editData = ref({});
        const activeItem = ref('home');
        const tableData = ref([]);
        const userRole = ref(true);
        const evidenceOrder = ref([]);
        const isGroupsetModal = ref(false);
        const isError = ref(false);
        let csv;

        const router = ref(props.router);

        const $toast = useToast();

        const csvConfig = mkConfig({
            useKeysAsHeaders: true
        });

        const fetchUserInfo = async () => {
            isLoading.value = true;
            try {
                const response = await axios
                    .get(
                        `${props.baseUrl}/v1/users/me`, {
                            headers: {
                                Authorization: `Bearer ${props.token}`,
                            },
                        }
                    );
                getError.value = null;
                userRole.value = response.data.roles.admin;
            } catch (error) {
                getError.value = error;
                userRole.value = null;
            } finally {
                isLoading.value = false;
            }
        }

        const fetchGroupset = async () => {
            try {
                const response = await axios.get(`${props.baseUrl}/v1.1/private/groupsets`, {
                    headers: {
                        Authorization: `Bearer ${props.token}`,
                    },
                });
                getError.value = null;
                if (!response.data.results.length) isGroupsetModal.value = true;
            } catch (error) {
                getError.value = error;
            }
        };

        const fetchData = async () => {
            isLoading.value = true;
            try {
                const response = await axios
                    .post(
                        `${props.baseUrl}/v1/private/itineraries/totals`, {}, {
                            headers: {
                                Authorization: `Bearer ${props.token}`,
                            },
                        }
                    );
                getError.value = null;
                switch (response.data.length) {
                    case 0:
                        totals.value = published.value = 0
                        break;
                    case 1:
                        totals.value = response.data[0].total;
                        response.data[0].status === "DRAFT" ? published.value = 0 : published.value = response.data[0].total
                        break;
                    default:
                        totals.value = response.data[0].total + response.data[1].total;
                        published.value = response.data.filter(val => val.status === 'PUBLISHED')[0].total;
                        break;
                }
            } catch (error) {
                getError.value = error;
                totals.value = published.value = null;
            } finally {
                isLoading.value = false;
            }
        };

        const fetchTableData = async () => {
            isLoading.value = true;
            try {
                const response = await axios
                    .get(`${props.baseUrl}/v1.1/private/itineraries`, {
                        headers: {
                            Authorization: `Bearer ${props.token}`
                        }
                    });
                getError.value = null;
                tableData.value = response.data?.results?.map(val =>
                    ({
                        NOME: val.name,
                        RAGGRUPPAMENTO: val.groupset.name,
                        ULTIMAMODIFICA: val.updatedAt ? val.updatedAt.split('T')[0] + ' ' + val.updatedAt.split('T')[1].split('.')[0] : '',
                        STATUS: val.status.name,
                        INEVIDENZA: val.markedPath ? 'In evidenza' : 'Non in evidenza',
                        ALT: val.images.length ? val.images[0].alt : 'image',
                        Avatar: val.images.length ?
                            `${props.baseUrl}/v1/public/image-files/` + val.images[0].uuid : '',
                        ID: val.uuid
                    })
                )
                csv = generateCsv(csvConfig)(tableData.value);
                isError.value = false;
            } catch (error) {
                getError.value = error;
                tableData.value = null;
                isError.value = true;
            } finally {
                isLoading.value = false
            }
        };

        const handleModal = () => {
            isModal.value = '';
            fetchData();
        }

        const handleExportModal = async () => {
            const modalProps = {
                modalTitle: 'Esporta Lista'
            }
            const modal = await openModal(SelectModal, modalProps)
            modal.on('return', () => {
                handleExport();
            })
        }

        const handleExport = () => {
            download(csvConfig)(csv)
        }

        const handleEdit = (payload) => {
            const routeExists = router.value.getRoutes().some(route => route.name === 'EditRoute');

            if (routeExists) {
                router.value.removeRoute('EditRoute');
            }
  
            router.value.addRoute({
                path: '/edit-route',
                name: 'EditRoute',
                component: EditRouteView,
                props: {
                    editorId: payload.ID,
                    router: router.value,
                },
            });

            // Navigate to the route with the ID as a parameter
            router.value.push({ name: 'EditRoute' });
            editData.value = payload;
        }

        const handleOrder = (payload) => {
            evidenceOrder.value = payload;
        }

        const saveOrder = () => {
            isLoading.value = true;
            axios.
            post(
                `${props.baseUrl}/v1/private/itineraries/order`,
                [...evidenceOrder.value], {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${props.token}`,
                    },
                }
            ).
            then((res) => {
                getError.value = null;
                console.log(res)
                $toast.success('Successo!', {
                    position: 'top-right'
                });
            }).
            catch(error => {
                getError.value = error;
            }).
            finally(() => isLoading.value = false)
        }

        const isActive = menuItem => {
            return activeItem.value === menuItem;
        }

        const setActive = menuItem => {
            activeItem.value = menuItem;
        }

        const warningGoback = () => {
            router.value.go(-1);
        }

        const addRouteAndNavigate = () => {
            router.value.addRoute({
                path: '/add-route',
                name: 'AddRoute',
                component: AddRouteView,
                props: {
                    router: router.value,
                }
            });

            router.value.push({ name: 'AddRoute' });
        }

        onMounted(() => {
            console.log('Loading @laculturapremia v', version);
            fetchData();
            fetchTableData();
            fetchUserInfo();
        })

        onBeforeMount(() => {
            fetchGroupset();
        })

        return {
            isLoading,
            isModal,
            isError,
            getError,
            totals,
            published,
            editData,
            activeItem,
            userRole,
            isGroupsetModal,
            props,
            handleModal,
            handleExportModal,
            handleEdit,
            handleExport,
            handleOrder,
            saveOrder,
            isActive,
            setActive,
            warningGoback,
            addRouteAndNavigate
        }
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,700;1,400;1,600&display=swap');

.text-title {
    color: var(--neutrali-nero, #1A1A1A);
    font-family: Titillium Web;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    /* 120% */
    letter-spacing: -1px;
}

.nav-item .nav-link {
    color: #4c4c4c !important;
}

.nav-item .nav-link.active {
    color: #1A1A1A !important;
}

.gap-16px {
    gap: 16px
}

.p-container {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 70px;
    padding-bottom: 70px;
}

p.text-overview {
    text-align: left;
}

.ml-auto {
    margin-left: auto;
}

.mt-48px {
    margin-top: 48px;
}

.modal-container {
    z-index: 1000;
}

.overlay-disable {
    width: 100%;
    height: 100%;
    position: fixed;
    opacity: 0.5;
    background-color: #4c4c4c;
    top: 0;
    z-index: 100;
}
</style>
