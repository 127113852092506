<template>
  <div v-if="isLoading">Loading...</div>
  <div v-else-if="getError">{{ getError }}</div>
  <draggable
    v-else
    tag="ul"
    :list="evidenceDisplay"
    class="d-flex flex-column mt-1 p-3 gap-16px bg-evidence-list"
    handle=".handle"
    item-key="uuid"
    @change="onDrag"
  >
    <template #item="{ element, index }">
      <li class="d-flex flex-row align-items-center">
        <div class="d-flex flex-row flex-1 align-items-center gap-24px">
          <div>{{ index + 1 }}.</div>
          <div class="d-flex flex-row align-items-center gap-12px">
            <img class="evidence-avatar" 
              :src="`${props.baseUrl}/v1/public/image-files/${element.imageId}`" 
              :alt="element.imageAlt" 
            />
            <div class="evidence-name-list">
              {{ element.name }}
            </div>
          </div>
        </div>
        <i class="handle drag-img">
          <img src="@/assets/icons/drag.png" />
        </i>
      </li>
    </template>
  </draggable>
</template>
<script>
import { ref, onMounted, watch } from 'vue';
import draggable from "vuedraggable";
import axios from 'axios';

export default {
  name: 'InEvidenceList',
  components: {
    draggable
  },
  props: {
    baseUrl: {
      type: String
    },
    token: {
      type: String
    }
  },
  emits: ['handleOrder'],
  setup(props, {emit}) {
    const evidenceData = ref([]);
    const evidenceDisplay = ref([]);
    const isLoading = ref(false);
    const getError = ref(null);

    const fetchEvidenceData = async () => {
      isLoading.value = true;
      try {
        const response = await axios
        .get(`${props.baseUrl}/v1.1/private/itineraries`, {
          params: {
            marked: true,
            orderBy: "position"
          },
          headers: {
            Authorization: `Bearer ${props.token}`
          }
        });
        getError.value = null;
        evidenceData.value = response.data.results.filter(val => val.markedPath && val);
      } catch (error) {
        getError.value = error;
        evidenceData.value = null;
      } finally {
        isLoading.value = false;
      }
    }

    watch(evidenceData, newValue => {
      evidenceDisplay.value = newValue.map(val => ({
        uuid: val.uuid,
        name: val.name,
        imageId: val.images.length ? val.images[0].uuid : '',
        imageAlt: val.images.length ? val.images[0].alt : '',
      }))
      emit('handleOrder', newValue.map(val => val.uuid));
    })

    onMounted(() => {
      fetchEvidenceData();
    })

    const onDrag = () => {
      emit('handleOrder', evidenceDisplay.value.map(val => val.uuid));
    }

    return {
      evidenceDisplay,
      isLoading,
      getError,
      props,
      onDrag
    }
  }
}
</script>

<style>
.gap-12px {
  gap: 12px;
}

.evidence-name-list {
  color: var(--neutrali-nero, #1A1A1A);
  font-family: Titillium Web;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 112.5% */
}

.bg-evidence-list {
  border-radius: 8px;
  background: var(--neutrali-grigio-chiarissimo, #FAFAFA);
}

.bg-evidence-list .evidence-avatar {
  width: 38px;
  height: 38px;
  border-radius: 100%;
}

i.drag-img:hover {
  cursor: grab;
}

.drag-img > img {
  width: 24px !important;
  height: 24px !important;
}

ul.bg-evidence-list > li:hover {
  background-color: #EAEAEA;
}
</style>