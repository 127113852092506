<template>
<div class="d-flex flex-column flex-grow-1">
    <div class="title-input">
        {{ props.title }}
    </div>
    <textarea class="w-100 main-input mt-1" v-model="content" :rows="props.rows" :placeholder="props.placeholder" :maxlength="props.maxNumber" :disabled="props.disabled">
      </textarea>
    <div class="d-flex justify-content-end length-input mt-1">
        {{ content.length }} &nbsp; / &nbsp; {{ props.maxNumber }}
    </div>
</div>
</template>

<script>
import {
    ref,
    watch
} from 'vue'
export default {
    name: 'PremialitaTextArea',
    props: {
        title: {
            type: String,
        },
        text: {
            type: String
        },
        maxNumber: {
            type: Number
        },
        language: {
            type: String
        },
        rows: {
            type: Number
        },
        placeholder: {
            type: String
        },
        disabled: {
            type: Boolean
        }
    },
    emits: ['handleText'],
    setup(props, {
        emit
    }) {
        const content = ref(props.text);

        watch(() => props.text, newValue => {
            content.value = newValue;
        })

        watch(content, newValue => {
            emit('handleText', {
                text: newValue,
                lang: props.language
            });
        })

        return {
            props,
            content
        }
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,700;1,400;1,600&display=swap');

.title-input {
    color: var(--neutrali-grigio-scuro, #4C4C4C);
    /* label/semibold_sm */
    font-family: Titillium Web;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 171.429% */
}

.length-input {
    color: var(--neutrali-nero, #1A1A1A);
    /* body-text/mono@sm */
    font-family: Roboto Mono;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
}

.main-input {
    border-radius: 4px;
    border: 1px solid var(--neutrali-grigio, #BCBCBC);
    background: var(--neutrali-bianco, #FFF);
    padding: 7px;

}
</style>
