<template>
<sortOnlyItinerary v-if="props.sortOnly" :baseUrl="props.baseUrl" :token="props.token" :router="props.router" />
<routesView v-else :baseUrl="props.baseUrl" :token="props.token" :router="props.router" :route="props.route" />
</template>

<script>
import routesView from './routesView.vue';
import sortOnlyItinerary from './sortOnlyItinerary.vue';
export default {
    name: 'ItineraryModule',
    components: {
        routesView,
        sortOnlyItinerary
    },
    props: {
        sortOnly: {
            type: Boolean,
        },
        token: {
            type: String,
        },
        baseUrl: {
            type: String
        },
        router: {
            type: Object
        },
        route: {
            type: Object
        }
    },
    setup(props) {
        return {
            props
        }
    }
}
</script>
