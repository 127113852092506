<template>
<div class="offer-point-container d-flex">
    <p>{{ props.title }}</p>
    <div class="d-flex gap-2">
        <div class="point-icon" @click="onPointDecreasement" :class="pointValue <= 0 && 'disabled'">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8" />
            </svg>
        </div>
        <div class="fw-bold point-value">{{ pointValue }}%</div>
        <div class="point-icon" @click="onPointIncreasement">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
            </svg>
        </div>
    </div>
</div>
</template>

<script>
import {
    toRef
} from 'vue';
export default {
    name: 'PremialitaOfferPoint',
    props: {
        title: {
            type: String
        },
        value: {
            type: Number
        },
        step: {
            type: Number
        }
    },
    emits: ['change'],
    setup(props, {
        emit
    }) {
        const pointValue = toRef(props, 'value');
        const onPointIncreasement = () => {
            emit('change', pointValue.value + props.step);
        }
        const onPointDecreasement = () => {
            emit('change', pointValue.value - props.step);
        }
        return {
            props,
            pointValue,
            onPointIncreasement,
            onPointDecreasement
        }
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,700;1,400;1,600&display=swap');

.offer-point-container p {
    width: 75%;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: center;
    margin: 0;
    margin-right: 32px;
}

.point-icon {
    width: 24px;
    height: 24px;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
}

.offer-point-container .point-value {
    width: 32px;
    user-select: none;
}

.offer-point-container div.disabled {
    opacity: 0.5;
    pointer-events: none;
}
</style>
