<template>
  <div :class="`${props.type}-status`">
    {{ props.text }}
  </div>
</template>
<script>
export default {
  name: 'StatusComponent',
  props: {
    text: {
      type: String
    },
    type: {
      type: String
    }
  },
  setup(props) {
    return {
      props
    }
  }
}
</script>

<style>
.primary-status {
  display: inline-flex;
  padding: 4px 4px 5px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: 0.5px solid #02E498;
  background: #F5FFFC;
  color: var(--status-alert-verde-alert, #00B377);
  font-family: Titillium Web;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px
}

.medium-status {
  display: inline-flex;
  padding: 4px 4px 5px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: 0.5px solid #F90;
  background: #F5FFFC;
  color: var(--status-alert-verde-alert, #CC7A00);
  font-family: Titillium Web;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px
}

.warning-status {
  display: inline-flex;
  padding: 4px 4px 5px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: 0.5px solid #D65C70;
  background: #FDF7F8;
  color: var(--status-alert-verde-alert, #CC334D);
  font-family: Titillium Web;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px
}

.disable-status {
  display: inline-flex;
  padding: 4px 4px 5px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: 0.5px solid #BCBCBC;
  background: var(--neutrali-bianco, #FFF);
  color: var(--status-alert-verde-alert, #8F8F8F);
  font-family: Titillium Web;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px
}

.invisible-status {
  display: inline-flex;
  padding: 4px 4px 5px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: 0.5px solid var(--neutrali-bianco, #FFF);

  background: #FFFFFF;
  color: var(--neutrali-bianco, #FFF);
  font-family: Titillium Web;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px
}
</style>