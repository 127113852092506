<template>
<div class="p-3">
    <button class="d-flex flex-row gx-4px border-0 bg-white mb-3" @click="handleGoBack">
        <img src="@/assets/icons/arrow-back.png" />
        <div class="arrow-button">TORNA A ORDINA ELEMENTI</div>
    </button>

    <div class="d-flex flex-col w-100">
        <div class="d-flex flex-row w-100">
            <h1 class="text-title">Ordinamento itinerari in evidenza</h1>
            <div class="ml-auto d-flex flex-row gap-16px">
                <ButtonComponent text="Salva" type="primary" :class="isDisableSave ? 'bg-disabled' :'bg-primary-custom'" :isDisable="isDisableSave" @handleClick="handleSave" />
            </div>
        </div>
    </div>

    <Loading v-if="isLoading" v-model:active="isLoading" :can-cancel="false" :is-full-page="true" color="#2D489D" loader="dots"></Loading>
    <draggable v-else tag="ul" :list="evidenceDisplay" class="d-flex flex-column mt-1 p-3 gap-16px bg-evidence-list" handle=".handle" item-key="uuid" @change="handleDrag">
        <template #item="{ element, index }">
            <li class="d-flex flex-row align-items-center">
                <div class="d-flex flex-row flex-1 align-items-center gap-24px">
                    <div>{{ index + 1 }}.</div>
                    <div class="d-flex flex-row align-items-center gap-12px">
                        <img class="evidence-avatar" :src="`${props.baseUrl}/v1/public/image-files/${element.imageId}`" :alt="element.imageAlt" />
                        <div class="evidence-name-list">
                            {{ element.name }}
                        </div>
                    </div>
                </div>
                <i class="handle drag-img">
                    <img src="@/assets/icons/drag.png" />
                </i>
            </li>
        </template>
    </draggable>

    <BaseModal :isOpen="isError" @modal-close="() => ''" name="warning-modal">
        <template #header>
            <h3>
                Avvertimento!
            </h3>
        </template>
        <template #content>
            <h5>Non possiamo recuperare i dati per il tuo itinerario di viaggio.</h5>
        </template>
        <template #footer>
            <ButtonComponent text="Indietro" type="regular" class="bg-white" :isDisable="false" @handleClick="handleGoBack" />
        </template>
    </BaseModal>

</div>
</template>

<script>
import {
    ref,
    onBeforeMount,
    watch
} from 'vue'
import axios from 'axios'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import draggable from "vuedraggable";
import BaseModal from '@/components/BaseModal.vue';

import ButtonComponent from '../components/Button.vue'
export default {
    name: 'SortOnlyItinerary',
    components: {
        ButtonComponent,
        Loading,
        draggable,
        BaseModal
    },
    props: {
        token: {
            type: String,
        },
        baseUrl: {
            type: String
        },
        router: {
            type: Object
        },
    },
    setup(props) {
        const router = ref(props.router);
        const evidenceData = ref([]);
        const isLoading = ref(false);
        const getError = ref(null);
        const evidenceDisplay = ref([]);
        const isDisableSave = ref(true);
        const isError = ref(false);

        const fetchEvidenceData = async () => {
            isLoading.value = true;
            try {
                const response = await axios
                    .get(`${props.baseUrl}/v1.1/private/itineraries`, {
                        params: {
                            marked: true,
                            orderBy: "position"
                        },
                        headers: {
                            Authorization: `Bearer ${props.token}`
                        }
                    });
                getError.value = null;
                evidenceData.value = response.data?.results?.filter(val => val.markedPath && val);
                isError.value = false;
                console.log(evidenceData.value);
            } catch (error) {
                getError.value = error;
                evidenceData.value = null;
                isError.value = true;
            } finally {
                isLoading.value = false;
            }
        }

        watch(evidenceData, newValue => {
            evidenceDisplay.value = newValue?.map(val => ({
                uuid: val.uuid,
                name: val.name,
                imageId: val.images.length ? val.images[0].uuid : '',
                imageAlt: val.images.length ? val.images[0].alt : '',
            }))
        })

        const handleGoBack = () => {
            if (router.value !== undefined || router.value !== null)
                router.value.go(-1);
        }

        const handleSave = async () => {
            isLoading.value = true;
            try {
                const response = await axios
                    .post(
                        `${props.baseUrl}/v1/private/itineraries/order`,
                        [...evidenceDisplay.value?.map(val => val.uuid)], {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${props.token}`,
                            },
                        }
                    );
                getError.value = null;
                isDisableSave.value = true;
                console.log(response);
            } catch (error) {
                getError.value = error;
            } finally {
                isLoading.value = false;
            }
        }

        const handleDrag = () => {
            isDisableSave.value = false;
        }

        onBeforeMount(() => {
            isDisableSave.value = true;
            fetchEvidenceData();
        })
        return {
            props,
            isLoading,
            getError,
            evidenceDisplay,
            isDisableSave,
            isError,
            handleDrag,
            handleGoBack,
            handleSave
        }
    }
}
</script>

<style>
.gap-12px {
    gap: 12px;
}

.evidence-name-list {
    color: var(--neutrali-nero, #1A1A1A);
    font-family: Titillium Web;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 112.5% */
}

.bg-evidence-list {
    border-radius: 8px;
    background: var(--neutrali-grigio-chiarissimo, #FAFAFA);
}

.bg-evidence-list .evidence-avatar {
    width: 38px;
    height: 38px;
    border-radius: 100%;
}

i.drag-img:hover {
    cursor: grab;
}

.drag-img>img {
    width: 24px !important;
    height: 24px !important;
}

ul.bg-evidence-list>li:hover {
    background-color: #EAEAEA;
}
</style>
