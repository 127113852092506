<template>
<div class="modal fade show d-block" tabindex="-1" role="dialog" raria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmeModalLongTitle">{{ props.modalTitle }}</h5>
            </div>
            <div class="modal-body">
                <div>Per salvare l'itinerario deve essere completato l'inserimento dei seguenti campi:</div>
                <div v-for="(item, index) in validateText" :key="index" v-html="item" class="px-3"></div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" @click="hideModal">Conferma</button>
            </div>
        </div>
    </div>
</div>
</template>

  
<script>
import {
    closeModal
} from 'jenesius-vue-modal'
import {
    ref
} from 'vue'
export default ({
    name: "ValidateModal",
    props: {
        modalTitle: {
            type: String,
        },
        modalContent: {
            type: Array
        }
    },
    setup(props) {
        const validateText = ref(props.modalContent);

        const hideModal = () => {
            closeModal()
        }

        return {
            props,
            validateText,
            hideModal
        }
    }
})
</script>
