<template>
<div class="p-3">
    <button class="d-flex flex-row gx-4px border-0 bg-white" @click="handlePageModal">
        <img src="@/assets/icons/arrow-back.png" />
        <div class="arrow-button">INDIETRO</div>
    </button>

    <div class="d-flex flex-col w-100">
        <div class="d-flex flex-row w-100">
            <h1 class="text-title">Attività premiante</h1>
            <div class="ml-auto d-flex flex-row gap-16px">
                <premialita-button text="Salva bozza" type="regular" class="bg-white" :isDisable="false" @handleClick="() => handleEdit(status === 'DRAFT' ? 'DRAFT' : 'PUBLISHED')" />
                <premialita-button :text="status === 'DRAFT' ? 'Pubblica attività' : 'RIMUOVI PUBBLICAZIONE'" type="primary" class="bg-primary-custom" :isDisable="false" @handleClick="() => handleEdit(status === 'DRAFT' ? 'PUBLISHED' : 'DRAFT')" />
            </div>
        </div>
    </div>

    <div class="mt-48px d-flex flex-row gap-16px ">
        <div class="create-route-panel-body flex-1 ">
            <div class="d-flex flex-row gap-16px w-100">
                <premialita-input v-if="langCode === 'it-IT'" title="Titolo" :max-number="50" :text="textVar[0].value" placeholder="Inserisci un titolo" language="it-IT" @handleText="handleTitle" />
                <premialita-input v-else title="Title" :max-number="50" :text="textVar[5].value" placeholder="Enter a title" language="en-EN" @handleText="handleTitle" />
                <premialita-input v-if="langCode === 'it-IT'" title="Sottotitolo" :max-number="50" :text="textVar[1].value" placeholder="Inserisci un sottotitolo" language="it-IT" @handleText="handleSubTitle" />
                <premialita-input v-else title="Subtitle" :max-number="50" :text="textVar[6].value" placeholder="Enter a subtitle" language="en-EN" @handleText="handleSubTitle" />
            </div>
            <div class="d-flex flex-row gap-16px w-100">
                <premialita-text-area v-if="langCode === 'it-IT'" title="Come ottenere i punti" :max-number="500" :rows="4" :placeholder="'Inserisci istruzioni'" language="it-IT" :text="textVar[3].value" @handleText="handlePointMethod" />
                <premialita-text-area v-else title="How to get points" :max-number="500" :rows="4" :placeholder="'Enter instructions'" language="en-EN" :text="textVar[8].value" @handleText="handlePointMethod" />
                <premialita-text-area v-if="langCode === 'it-IT'" title="Validità punti" :max-number="500" :rows="4" :placeholder="'Inserisci termini e condizioni'" language="it-IT" :text="textVar[4].value" @handleText="handlePointValidity" />
                <premialita-text-area v-else title="Points validity" :max-number="500" :rows="4" :placeholder="'Enter terms and conditions'" language="en-EN" :text="textVar[9].value" @handleText="handlePointValidity" />
            </div>
            <div class="d-flex flex-row gap-16px w-100">
                <div class="flex-1">
                    <premialita-text-area v-if="langCode === 'it-IT'" title="Descrizione" :max-number="2000" :rows="8" :placeholder="'Inserisci una descrizione'" language="it-IT" :text="textVar[2].value" @handleText="handleDescription" />
                    <premialita-text-area v-else title="Description" :max-number="2000" :rows="8" :placeholder="'Enter a description'" language="en-EN" :text="textVar[7].value" @handleText="handleDescription" />
                </div>
                <div class="flex-1">
                    <premialita-image-upload :token="props.token" :baseUrl="props.baseUrl" :imageData="imagesData" @handleUpload="handleUpload" />
                </div>
            </div>
            <div class="d-flex flex-row gap-16px w-100">
                <div class="w-50 radio-select" v-if="isLoading">
                    <Loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" color="#2D489D" loader="dots"></Loading>
                </div>
                <div class="w-50 radio-select" v-else>
                    <div class="d-flex flex-column gap-8px mb-2">
                        <p class="p-label">Raggruppamento</p>
                        <p class="p-text">{{ groupset.name }}</p>
                    </div>
                    <div class="d-flex flex-column gap-8px">
                        <p class="p-label">Tipo di attività</p>
                        <p class="p-text">{{ type.name }}</p>
                    </div>
                    <div v-if="type?.name === 'PERCORSO'" class="d-flex flex-column gap-8px">
                        <p class="p-label">Itinerario</p>
                        <div class="p-text" @click="addRouteEdit">{{ itemData.name }}</div>
                    </div>
                    <div v-else-if="type?.name === 'QUESTIONARIO'" class="d-flex flex-column gap-8px">
                        <p class="p-label">Questionario</p>
                        <router-link :to="`${props.baseRouter}/surveys`" class="p-text">{{ itemData.name }}</router-link>
                    </div>
                </div>
                <div class="w-50 point-section">
                    <PointComponent :title="'Punti premio'" v-model:points="points" :max-length="2000" />
                    <PointComponent v-if="type?.name !== 'QUESTIONARIO'" :title="'Per numero minimo di tappe'" v-model:points="minStageNo" :max-length="2000" />
                    <PointComponent v-if="type?.name !== 'QUESTIONARIO'" :title="'Validità punti, in gg. (da inizio attività)'" v-model:points="duration" :max-length="2000" />
                </div>
            </div>
        </div>
        <div>
            <premialita-language @handleLangCode="handleLangCode" @handleDel="handleDel" />
        </div>
    </div>
    <premialita-modal :isOpen="isValidateModal">
        <template #header>
            <h1 class="font-display-4">Avvertimento</h1>
        </template>
        <template #content>
            <h5>Per salvare l'activité deve essere completato l'inserimento dei seguenti campi:</h5>
            <div v-for="(item, index) in validateText" :key="index" v-html="item" class="px-3"></div>
        </template>
        <template #footer>
            <premialita-button text="Conferma" type="primary" class="bg-primary-custom" :isDisable="false" @handleClick="handleValidateModal" />
        </template>
    </premialita-modal>
</div>
</template>

<script>
import PointComponent from '@/components/PointComponent.vue';
import ItineraryEdit from '@/components/ItineraryEdit.vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import {
    useToast
} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import axios from 'axios';
import {
    ref,
    onBeforeMount,
    toRef,
} from 'vue';
export default {
    name: 'EditActivity',
    components: {
        Loading,
        PointComponent
    },
    props: {
        token: {
            type: String,
            required: true
        },
        baseUrl: {
            type: String,
            required: true
        },
        baseRouter: {
            type: String
        },
        editData: {
            type: String
        },
        groupsetData: {
            type: Object
        },
        router: {
            type: Object
        }
    },
    emits: ['handlePageModal', 'handleReload'],
    setup(props, {
        emit
    }) {
        const langCode = ref('it-IT');
        const imagesData = ref([]);
        const validateText = ref([]);
        const isValidateModal = ref(false);
        const editActivitiesData = ref(null);
        const points = ref(10);
        const minStageNo = ref(10);
        const duration = ref(60);
        const isLoading = ref(false);
        const status = ref('DRAFT');
        const groupset = ref("");
        const groupsetArray = toRef(props, 'groupsetData');
        const type = ref("Percorso");
        const itemData = ref(null);
        const router = toRef(props, 'router');

        const $toast = useToast();

        const textVar = ref([{
                key: 'labels.title',
                value: '',
                languageCode: 'it-IT'
            },
            {
                key: 'labels.subtitle',
                value: '',
                languageCode: 'it-IT'
            },
            {
                key: 'labels.description',
                value: '',
                languageCode: 'it-IT'
            },
            {
                key: 'labels.pointmethod',
                value: '',
                languageCode: 'it-IT'
            },
            {
                key: 'labels.pointvalidity',
                value: '',
                languageCode: 'it-IT'
            },
            {
                key: 'labels.title',
                value: '',
                languageCode: 'en-EN'
            },
            {
                key: 'labels.subtitle',
                value: '',
                languageCode: 'en-EN'
            },
            {
                key: 'labels.description',
                value: '',
                languageCode: 'en-EN'
            },
            {
                key: 'labels.pointmethod',
                value: '',
                languageCode: 'en-EN'
            },
            {
                key: 'labels.pointvalidity',
                value: '',
                languageCode: 'en-EN'
            }
        ]);

        const handleTitle = (payload) => {
            payload.lang === 'it-IT' ? textVar.value[0] = {
                ...textVar.value[0],
                value: payload.text
            } : textVar.value[5] = {
                ...textVar.value[5],
                value: payload.text
            }
        }

        const handleSubTitle = (payload) => {
            payload.lang === 'it-IT' ? textVar.value[1] = {
                ...textVar.value[1],
                value: payload.text
            } : textVar.value[6] = {
                ...textVar.value[6],
                value: payload.text
            }
        }

        const handlePointMethod = (payload) => {
            payload.lang === 'it-IT' ? textVar.value[3] = {
                ...textVar.value[3],
                value: payload.text
            } : textVar.value[8] = {
                ...textVar.value[8],
                value: payload.text
            }
        }

        const handlePointValidity = (payload) => {
            payload.lang === 'it-IT' ? textVar.value[4] = {
                ...textVar.value[4],
                value: payload.text
            } : textVar.value[9] = {
                ...textVar.value[9],
                value: payload.text
            }
        }

        const handleDescription = (payload) => {
            payload.lang === 'it-IT' ? textVar.value[2] = {
                ...textVar.value[2],
                value: payload.text
            } : textVar.value[7] = {
                ...textVar.value[7],
                value: payload.text
            }
        }

        const handleUpload = (payload) => {
            imagesData.value = payload;
        }

        const isEmpty = (str) => {
            return (str === null || str === undefined || str.length <= 0) ? true : false;
        }

        const fetchItineraryByID = async (payload, type) => {
            let api = 'itineraies';
            switch (type.name) {
                case 'PERCORSO':
                    api = 'itineraries';
                    break;
                case 'QUESTIONARIO':
                    api = 'surveys';
                    break;
                case 'OFFERTA':
                    api = 'offers';
                    break;
                default:
                    break;
            }
            isLoading.value = true;
            try {
                const res = await axios.get(`${props.baseUrl}/v1/public/${api}/${payload}`, {
                    headers: {
                        Authorization: `Bearer ${props.token}`,
                    }
                });
                itemData.value = res.data;
                console.log('res-->>', itemData.value)
            } catch(err) {
                console.log('err-->>', err)
            } finally {
                isLoading.value = false;
            }
        }

        const fetchEditData = async () => {
            isLoading.value = true;
            try {
                const res = await axios.get(`${props.baseUrl}/v1/private/activities/${props.editData}`, {
                    headers: {
                        Authorization: `Bearer ${props.token}`,
                    }
                });

                await fetchItineraryByID(res.data.itemId, res.data.type);

                editActivitiesData.value = res.data;
                if (res.data.textFields.length) {
                    if (res.data.textFields.filter(val => val.key === "labels.title").length) {
                        textVar.value[0].value =
                            res.data.textFields.filter(val => val.key === "labels.title").filter(val => val.languageCode === 'it-IT').length ?
                            res.data.textFields.filter(val => val.key === "labels.title").filter(val => val.languageCode === 'it-IT')[0].value :
                            '';
                        textVar.value[5].value =
                            res.data.textFields.filter(val => val.key === "labels.title").filter(val => val.languageCode === 'en-EN').length ?
                            res.data.textFields.filter(val => val.key === "labels.title").filter(val => val.languageCode === 'en-EN')[0].value :
                            '';
                    }
                    if (res.data.textFields.filter(val => val.key === "labels.subtitle").length) {
                        textVar.value[1].value =
                            res.data.textFields.filter(val => val.key === "labels.subtitle").filter(val => val.languageCode === 'it-IT').length ?
                            res.data.textFields.filter(val => val.key === "labels.subtitle").filter(val => val.languageCode === 'it-IT')[0].value :
                            '';
                        textVar.value[6].value =
                            res.data.textFields.filter(val => val.key === "labels.subtitle").filter(val => val.languageCode === 'en-EN').length ?
                            res.data.textFields.filter(val => val.key === "labels.subtitle").filter(val => val.languageCode === 'en-EN')[0].value :
                            '';
                    }
                    if (res.data.textFields.filter(val => val.key.includes("labels.desc")).length) {
                        textVar.value[2].value =
                            res.data.textFields.filter(val => val.key.includes("labels.desc")).filter(val => val.languageCode === 'it-IT').length ?
                            res.data.textFields.filter(val => val.key.includes("labels.desc")).filter(val => val.languageCode === 'it-IT')[0].value :
                            '';
                        textVar.value[7].value =
                            res.data.textFields.filter(val => val.key.includes("labels.desc")).filter(val => val.languageCode === 'en-EN').length ?
                            res.data.textFields.filter(val => val.key.includes("labels.desc")).filter(val => val.languageCode === 'en-EN')[0].value :
                            '';
                    }
                    if (res.data.textFields.filter(val => val.key.includes("labels.pointmethod")).length) {
                        textVar.value[3].value =
                            res.data.textFields.filter(val => val.key.includes("labels.pointmethod")).filter(val => val.languageCode === 'it-IT').length ?
                            res.data.textFields.filter(val => val.key.includes("labels.pointmethod")).filter(val => val.languageCode === 'it-IT')[0].value :
                            '';
                        textVar.value[8].value =
                            res.data.textFields.filter(val => val.key.includes("labels.pointmethod")).filter(val => val.languageCode === 'en-EN').length ?
                            res.data.textFields.filter(val => val.key.includes("labels.pointmethod")).filter(val => val.languageCode === 'en-EN')[0].value :
                            '';
                    }
                    if (res.data.textFields.filter(val => val.key.includes("labels.pointvalidity")).length) {
                        textVar.value[4].value =
                            res.data.textFields.filter(val => val.key.includes("labels.pointvalidity")).filter(val => val.languageCode === 'it-IT').length ?
                            res.data.textFields.filter(val => val.key.includes("labels.pointvalidity")).filter(val => val.languageCode === 'it-IT')[0].value :
                            '';
                        textVar.value[9].value =
                            res.data.textFields.filter(val => val.key.includes("labels.pointvalidity")).filter(val => val.languageCode === 'en-EN').length ?
                            res.data.textFields.filter(val => val.key.includes("labels.pointvalidity")).filter(val => val.languageCode === 'en-EN')[0].value :
                            '';
                    }
                }
                imagesData.value = res.data.images;
                minStageNo.value = res.data.minStageNumber,
                    points.value = res.data.points;
                status.value = res.data.status.name;
                groupset.value = groupsetArray.value.find(val => val.uuid === res.data?.groupset?.uuid);
                type.value = res.data.type;
                console.log('***-----**-->>>>', res.data, '::-->>', groupset.value)
            } catch (err) {
                console.log('***', err)
            } finally {
                isLoading.value = false;
            }
        }

        const handleEdit = async (statusVar) => {
            if (
                !isEmpty(textVar.value[0].value) && !isEmpty(textVar.value[2].value) && !isEmpty(textVar.value[5].value) &&
                !isEmpty(textVar.value[7].value) && !isEmpty(imagesData.value)
            ) {
                isLoading.value = true;
                try {
                    await axios.
                    put(
                        `${props.baseUrl}/v1/private/activities/${editActivitiesData.value.uuid}`, {
                            name: textVar.value[0].value,
                            groupsetId: groupset.value.uuid,
                            type: type.value.name,
                            status: statusVar,
                            textFields: [...textVar.value].map(val => ({
                                ...val
                            })),
                            itemId: editActivitiesData.value.itemId,
                            images: [...imagesData.value].map(val => val.uuid),
                            points: points.value,
                            minStageNumber: minStageNo.value,
                            marked: editActivitiesData.value.marked
                        }, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${props.token}`,
                            },
                        }
                    )
                    $toast.success("L'attività è stata salvata correttamente.", {
                        position: 'top-right'
                    })
                    handlePageModal();
                } catch (err) {
                    console.log('err-->>', err)
                } finally {
                    emit('handleReload');
                    isLoading.value = false;
                }

                return true;
            }
            validateText.value = [];
            if (isEmpty(textVar.value[0].value)) validateText.value.push('<b>Titolo in Italiano</b>');
            if (isEmpty(textVar.value[2].value)) validateText.value.push('<b>Descrizione in Italiano</b>');
            if (isEmpty(textVar.value[5].value)) validateText.value.push('<b>Titolo in Inglese</b>');
            if (isEmpty(textVar.value[7].value)) validateText.value.push('<b>Descrizione in Inglese</b>');
            if (isEmpty(imagesData.value)) validateText.value.push('<b>Immagini</b>');
            isValidateModal.value = true;
        }

        const handlePageModal = () => {
            emit('handlePageModal')
        }

        const handleValidateModal = () => {
            isValidateModal.value = false;
        }

        const handleLangCode = (payload) => {
            langCode.value = payload === 'Italiano' ? 'it-IT' : 'en-EN';
        }

        const handleDel = async () => {
            isLoading.value = true;
            try {
                await axios
                    .delete(
                        `${props.baseUrl}/v1/private/activities/${editActivitiesData.value.uuid}`, {
                            headers: {
                                Authorization: `Bearer ${props.token}`,
                            },
                        }
                    )
                emit('handlePageModal');
                emit('handleReload');
            } catch (err) {
                return err;
            } finally {
                isLoading.value = false;
            }
        }

        const handleMinStateNo = (payload) => {
            minStageNo.value = payload;
        }

        const handlePoints = (payload) => {
            points.value = payload;
        }

        const handleDuration = (payload) => {
            duration.value = payload;
        }

        const addRouteEdit = () => {
            const routeExists = router.value.getRoutes().some(route => route.name === 'EditRoute');
  
            if (!routeExists) {
                router.value.addRoute({
                    path: '/edit-route',
                    name: 'EditRoute',
                    component: ItineraryEdit,
                    props: {
                        editorId: itemData.value.uuid,
                        router: router.value,
                    },
                });
            }

            // Navigate to the route with the ID as a parameter
            router.value.push({ name: 'EditRoute' });
        }

        onBeforeMount(() => {
            fetchEditData()
        })

        return {
            props,
            langCode,
            textVar,
            points,
            minStageNo,
            duration,
            isLoading,
            isValidateModal,
            validateText,
            imagesData,
            type,
            status,
            itemData,
            groupset,
            addRouteEdit,
            handleEdit,
            handlePageModal,
            handleTitle,
            handleSubTitle,
            handlePointMethod,
            handleDescription,
            handlePointValidity,
            handleUpload,
            handleLangCode,
            handleDel,
            handleValidateModal,
            handlePoints,
            handleMinStateNo,
            handleDuration
        }
    }
}
</script>

<style>
.gx-4px {
    gap: 4px;
}

.gx-8px {
    gap: 8px;
}

.p-label {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: #4C4C4C;
    margin: 0;
    font-family: 'Titillium Web'
}

.p-text {
    padding: 7px 20px;
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1A1A1A;
    font-family: 'Titillium Web'
}

.arrow-button {
    color: var(--neutrali-grigio-scuro, #4C4C4C);
    /* caption */
    font-family: Titillium Web;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 171.429% */
}

.create-route-panel-body {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    align-content: flex-start;
    flex-shrink: 0;
    flex-wrap: wrap;
    gap: 32px;
    border-radius: 8px;
    border: 1px solid var(--neutrali-grigio-segnaposto, #8F8F8F);
}

p.text-overview {
    text-align: left;
}

.flex-1 {
    flex: 1;
}

img {
    display: block;
}

.label-left {
    padding-right: 0 !important;
}

.label-right {
    padding-left: 0 !important;
}

.ml-auto {
    margin-left: auto;
}

.radio-container {
    display: block;
    position: relative;
    padding-left: 35px;
    padding-top: 17px;
    padding-bottom: 17px;
    margin-bottom: 8px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 12px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #FFF;
    border-radius: 50%;
    border: 1px solid #2D489D;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked~.checkmark {
    background-color: #2D489D;
}

.radio-select {
    padding-left: 30px;
}

.point-section {
    padding: 48px;
}

.w-50 {
    width: 50%;
}
</style>
