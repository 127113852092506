<template>
<div class="d-flex p-3 bg-language d-flex flex-column gap-24px">
    <div class="d-flex flex-column gap-8px">
        <div class="language-title">
            Seleziona lingua
        </div>
        <Multiselect :searchable="false" :show-labels="false" :allow-empty="false" v-model="selectedLan" :options="optionArray" :hideSelected="true"></Multiselect>
    </div>
    <LabelMultiSelect :labelData="labelData" :selectedLan="selectedLan" :selectedLabelData="selectedLabelData" @handleLabel="handleLabel" />
    <premialita-button text="Elimina elemento" type="remove" @handleClick="onModal" />
    <widget-container-modal />
</div>
</template>

    
<script>
import axios from 'axios';
import {
    ref,
    watch
} from 'vue'
import Multiselect from 'vue-multiselect';
import DeleteModal from './DeleteModal.vue';
import LabelMultiSelect from './LabelMultiSelect.vue';
import {
    container,
    openModal,
    closeModal
} from "jenesius-vue-modal";
export default {
    name: 'LanguageComponent',
    components: {
        LabelMultiSelect,
        Multiselect,
        WidgetContainerModal: container
    },
    props: {
        uuid: {
            type: String
        },
        itineraryTypesData: {
            type: Array
        },
        selectedTypesData: {
            type: Array
        },
        token: {
            type: String
        },
        baseUrl: {
            type: String
        }
    },
    emits: ['handleModal', 'handleLang', 'handleLabel'],
    setup(props, {
        emit
    }) {
        const selectedLan = ref('Italiano');
        const optionArray = ['Italiano', 'English'];
        const labelData = ref(props.itineraryTypesData);
        const selectedLabelData = ref([]);
        const text =
            "Stai per <u><b>rimuovere</b></u> un itinerario al momento visibile sull'app di Musei Italiani. Se procedi l'itinerario verrà eliminato e tutti i suoi dati andranno persi.<br/>Vuoi procedere?";
        // const textVar = ref([
        //   {
        //     languageCode: 'it-IT',
        //     key: 'label.key',
        //     value: ''
        //   },
        //   {
        //     languageCode: 'en-EN',
        //     key: 'label.key',
        //     value: ''
        //   },
        //   {
        //     languageCode: 'it-IT',
        //     key: 'label.value',
        //     value: ''
        //   },
        //   {
        //     languageCode: 'en-EN',
        //     key: 'label.value',
        //     value: ''
        //   },
        // ]);

        const handleDelete = async () => {
            try {
                await axios
                    .delete(
                        `${props.baseUrl}/v1/private/itineraries/${props.uuid}`, {
                            headers: {
                                Authorization: `Bearer ${props.token}`,
                            },
                        }
                    )
                emit('handleModal');
                closeModal();
            } catch (error) {
                console.log(error);
            }
        };

        const onModal = async () => {
            const modal = await openModal(DeleteModal, {
                modalTitle: 'Rimozione itinerario',
                modalContent: text
            });
            modal.on('ModalConfirm', () => {
                handleDelete();
            });
        }

        // const onLabelModal = async () => {
        //   await openModal(AddLabelModal, {
        //     modalTitle: "Aggiungi etichetta",
        //     token: props.token,
        //     baseUrl: props.baseUrl
        //   });
        // }

        const handleLabel = (payload) => {
            emit('handleLabel', payload);
        }

        watch(selectedLan, newValue => {
            emit('handleLang', newValue);
        })

        watch(() => props.itineraryTypesData, newValue => {
            labelData.value = newValue;
        })

        watch(() => props.selectedTypesData, newValue => {
            selectedLabelData.value = newValue;
        })

        return {
            selectedLan,
            text,
            optionArray,
            labelData,
            selectedLabelData,
            // textVar,
            handleDelete,
            onModal,
            // onLabelModal,
            handleLabel
        }
    }
}
</script>

    
<style>
.bg-language {
    border-radius: 5px;
    background: var(--neutrali-grigio-chiaro, #EBEBEB);
}

.gap-24px {
    gap: 24px;
}

.language-title {
    color: var(--neutrali-nero, #1A1A1A);
    /* link/sans-bold */
    font-family: Titillium Web;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 150% */
}

.modal-header {
    padding: 32px 32px 0 32px !important;
    border-bottom: none !important;
}

.modal-title {
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 32px !important;
}

.modal-body {
    padding: 32px !important;
}

.modal-body>div {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
}

.modal-footer {
    padding: 0 32px 32px !important;
    justify-content: flex-start !important;
    border-top: none !important;
}

.modal-footer>button {
    border: 1px solid #2D489D;
    border-radius: 4px;
    padding: 16px 32px;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
}

.modal-footer>button.btn-primary {
    background-color: #2D489D;
    color: white;
}

.modal-footer>button.btn-secondary {
    background-color: white;
    color: #2D489D;
}

@media screen and (min-width: 576px) {
    .modal-dialog {
        max-width: 646px !important;
    }
}
</style>
