<template>
<div class="p-3">
    <button class="d-flex flex-row gx-4px border-0 bg-white" @click="handleGoBack">
        <img src="@/assets/icons/arrow-back.png" />
        <div class="arrow-button">INDIETRO</div>
    </button>

    <div class="d-flex flex-col w-100">
        <div class="d-flex flex-row w-100">
            <h1 class="text-title">Itinerari</h1>
            <div class="ml-auto d-flex flex-row gap-16px">
                <ButtonComponent text="SALVA" type="regular" class="bg-white" :isDisable="false" @handleClick="() => handleEdit(status === 'DRAFT' ? 'DRAFT' : 'PUBLISHED')" />
                <ButtonComponent v-if="userRole" class="bg-primary-custom" :text="status === 'DRAFT' ? 'Pubblica itinerario' : 'RIMUOVI PUBBLICAZIONE'" type="primary" :isDisable="false" @handleClick="() => handleEdit(status === 'DRAFT' ? 'PUBLISHED' : 'DRAFT')" />
            </div>
        </div>
    </div>
    <div>
        <p class="text-overview">{{ published }} itinerari pubblicati su {{ totals }} presenti</p>
    </div>
    <div class="mt-48px d-flex flex-row gap-16px ">
        <div class="create-route-panel-body flex-1 ">
            <div class="d-flex flex-row gap-16px w-100">
                <InputComponent v-if="langCode === 'it-IT'" title="Titolo" :max-number="50" :text="textVar[0].value" placeholder="Inserisci un titolo" language="it-IT" @handleText="handleTitle" />
                <InputComponent v-else title="Title" :max-number="50" :text="textVar[5].value" placeholder="Enter a title" language="en-EN" @handleText="handleTitle" />
                <InputComponent v-if="langCode === 'it-IT'" title="Sottotitolo" :max-number="50" :text="textVar[1].value" placeholder="Inserisci un sottotitolo" language="it-IT" @handleText="handleSubTitle" />
                <InputComponent v-else title="Subtitle" :max-number="50" :text="textVar[6].value" placeholder="Enter a subtitle" language="en-EN" @handleText="handleSubTitle" />
            </div>
            <div class="d-flex flex-row gap-16px w-100">
                <TextAreaComponent v-if="langCode === 'it-IT'" title="Praticabilità" :max-number="500" :rows="4" :placeholder="'Inserisci una praticabilità'" language="it-IT" :text="textVar[3].value" @handleText="handlePracticability" />
                <TextAreaComponent v-else title="Practicability" :max-number="500" :rows="4" :placeholder="'Enter a Practicability'" language="en-EN" :text="textVar[8].value" @handleText="handlePracticability" />
                <TextAreaComponent v-if="langCode === 'it-IT'" title="Durata dell'itinerario" :max-number="500" :rows="4" :placeholder="'Inserisci una durata'" language="it-IT" :text="textVar[4].value" @handleText="handleDuration" />
                <TextAreaComponent v-else title="Duration of the route" :max-number="500" :rows="4" :placeholder="'Enter a duration'" language="en-EN" :text="textVar[9].value" @handleText="handleDuration" />
            </div>
            <div class="d-flex flex-row gap-16px w-100">
                <div class="flex-1 w-50">
                    <TextAreaComponent v-if="langCode === 'it-IT'" title="Descrizione" :max-number="2000" :rows="8" :placeholder="'Inserisci una descrizione'" language="it-IT" :text="textVar[2].value" @handleText="handleDescription" />
                    <TextAreaComponent v-else title="Description" :max-number="2000" :rows="8" :placeholder="'Enter a description'" language="en-EN" :text="textVar[7].value" @handleText="handleDescription" />
                </div>
                <div class="flex-1 w-50">
                    <ImageUploadComponent :token="token" :baseUrl="baseUrl" :imageData="imagesData" @handleUpload="handleUpload" />
                </div>
            </div>
            <div class="d-flex flex-row gap-16px w-100">
                <div class="flex-1">
                    <CheckerComponent title="Ticketing" label="L'itinerario prevede l'acquisto di un biglietto" @handleCheck="handleTicket" :isChecked="ticketRequired" />
                </div>
                <div class="flex-1">
                    <CheckerComponent v-show="userRole && status === 'PUBLISHED'" title="In evidenza" label="Metti l'itinerario “In evidenza”" @handleCheck="handleMarkedPath" :isChecked="markedPath" :disable="!userRole" />
                </div>
            </div>
            <div class="w-50 gap-8px">
                <div class="title-input">
                    Seleziona il raggruppamento inerente all'itinerario
                </div>
                <div v-if="isLoading">
                    <Loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" color="#2D489D" loader="dots"></Loading>
                </div>
                <div v-else-if="getError">{{ getError }}</div>
                <div v-else>
                    <Multiselect :searchable="false" :show-labels="false" :allow-empty="false" label="name" v-model="groupField" :options="groupsetData" :hideSelected="true" track-by="name"></Multiselect>
                </div>
            </div>
            <div class="d-flex flex-row w-100">
                <div class="w-50">
                    <SearchItemComponent @selectedMusuemFunc="selectedMusuemFunc" :museumData="museumAllData" :baseUrl="baseUrl" />
                </div>
                <div class="w-50 px-3">
                    <MuseumListComponent :selectedList="selectMuseums" @onMuseumOrderChange="onMuseumOrderChange" />
                </div>
            </div>
        </div>
        <div class=" ">
            <LanguageComponent @handleLang="handleLang" @handleModal="handleGoBack" @handleLabel="handleLabel" :uuid="props.editorId" :token="token" :baseUrl="baseUrl" :itineraryTypesData="itineraryTypesData" :selectedTypesData="selItineraryTypesData" />
        </div>
        <!-- Validate Modal -->
        <widget-container-modal />
    </div>
</div>
</template>

<script>
import ButtonComponent from '../components/Button.vue';
import InputComponent from '../components/Input.vue';
import TextAreaComponent from '../components/TextArea.vue';
import ImageUploadComponent from '../components/ImageUpload.vue'
import CheckerComponent from '../components/Checker.vue'
import SearchItemComponent from '../components/SearchItem.vue'
import LanguageComponent from '../components/Language.vue'
import MuseumListComponent from '../components/MuseumList.vue';
import ValidateModal from '../components/ValidateModal.vue';
import Multiselect from 'vue-multiselect';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import {
    useToast
} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import {
    ref,
    onMounted,
    watch,
    onBeforeMount
} from 'vue';
import {
    container,
    openModal
} from "jenesius-vue-modal";
import axios from 'axios';

export default {
    name: 'EditRouteView',
    components: {
        ButtonComponent,
        InputComponent,
        TextAreaComponent,
        ImageUploadComponent,
        CheckerComponent,
        SearchItemComponent,
        LanguageComponent,
        MuseumListComponent,
        Multiselect,
        Loading,
        WidgetContainerModal: container
    },
    props: {
        editorId: {
            type: String
        },
        router: {
            type: Object
        }
    },
    setup(props) {
        const groupsetData = ref([]);
        const isGroupsetFetch = ref(false);
        const editGroupset = ref('');
        const selectMuseums = ref([]);
        const groupField = ref({
            name: ''
        });
        const isLoading = ref(false);
        const getError = ref(null);
        const ticketRequired = ref(false);
        const markedPath = ref(false);
        const langCode = ref('it-IT');
        const itinerariesEditData = ref([]);
        const museumAllData = ref([]);
        const imagesData = ref([]);
        const status = ref('');
        const validateText = ref([]);
        const cnt = ref(0);
        const itineraryTypesData = ref([]);
        const selItineraryTypesData = ref([]);
        const labelData = ref([]);

        const $toast = useToast();
        const router = ref(props.router);
        const token = ref('');
        const baseUrl = 'https://ssot.laculturapremia.it/api';
        const totals = ref(0);
        const userRole = ref(true);
        const published = ref(0);

        const textVar = ref([{
                key: 'labels.title',
                value: '',
                languageCode: 'it-IT'
            },
            {
                key: 'labels.subtitle',
                value: '',
                languageCode: 'it-IT'
            },
            {
                key: 'labels.description',
                value: '',
                languageCode: 'it-IT'
            },
            {
                key: 'labels.practicability',
                value: '',
                languageCode: 'it-IT'
            },
            {
                key: 'labels.duration',
                value: '',
                languageCode: 'it-IT'
            },
            {
                key: 'labels.title',
                value: '',
                languageCode: 'en-EN'
            },
            {
                key: 'labels.subtitle',
                value: '',
                languageCode: 'en-EN'
            },
            {
                key: 'labels.description',
                value: '',
                languageCode: 'en-EN'
            },
            {
                key: 'labels.practicability',
                value: '',
                languageCode: 'en-EN'
            },
            {
                key: 'labels.duration',
                value: '',
                languageCode: 'en-EN'
            }
        ]);

        const fetchMuseums = async (groupset) => {
            isLoading.value = true;
            try {
                const response = await axios
                    .get(`${baseUrl}/v1/private/museums`, {
                        params: {
                            groupsetId: groupset.uuid
                        },
                        headers: {
                            Authorization: `Bearer ${token.value}`
                        }
                    });
                getError.value = null;
                museumAllData.value = response.data.
                map(val => ({
                    ...val,
                    flag: selectMuseums.value.map(item => item.uuid).includes(val.uuid)
                })).
                sort((a, b) => {
                    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    // names must be equal
                    return 0;
                });
            } catch (error) {
                getError.value = error;
                museumAllData.value = null;
            } finally {
                cnt.value = 2;
                isLoading.value = false;
            }
        };

        const fetchItinerariesTypes = async () => {
            isLoading.value = true;
            try {
                const response = await axios.get(`${baseUrl}/v1/public/itinerary-types`);
                getError.value = null;
                itineraryTypesData.value = response.data;
            } catch (error) {
                getError.value = error;
                itineraryTypesData.value = null;
            } finally {
                isLoading.value = false;
            }
        }

        const fetchDataById = async () => {
            isLoading.value = true;
            try {
                const response = await axios
                    .get(
                        `${baseUrl}/v1/private/itineraries/${props.editorId}`, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${token.value}`,
                            },
                        }
                    );
                getError.value = null;
                editGroupset.value = response.data.groupset;
                selectMuseums.value = response.data.museums;
                itinerariesEditData.value = response.data;
                imagesData.value = response.data.images;
                groupField.value = response.data.groupset;
                ticketRequired.value = response.data.ticketRequired;
                markedPath.value = response.data.markedPath;
                if (response.data.textFields.length) {
                    if (response.data.textFields.filter(val => val.key === "labels.title").length) {
                        textVar.value[0].value =
                            response.data.textFields.filter(val => val.key === "labels.title").filter(val => val.languageCode === 'it-IT').length ?
                            response.data.textFields.filter(val => val.key === "labels.title").filter(val => val.languageCode === 'it-IT')[0].value :
                            '';
                        textVar.value[5].value =
                            response.data.textFields.filter(val => val.key === "labels.title").filter(val => val.languageCode === 'en-EN').length ?
                            response.data.textFields.filter(val => val.key === "labels.title").filter(val => val.languageCode === 'en-EN')[0].value :
                            '';
                    }
                    if (response.data.textFields.filter(val => val.key === "labels.subtitle").length) {
                        textVar.value[1].value =
                            response.data.textFields.filter(val => val.key === "labels.subtitle").filter(val => val.languageCode === 'it-IT').length ?
                            response.data.textFields.filter(val => val.key === "labels.subtitle").filter(val => val.languageCode === 'it-IT')[0].value :
                            '';
                        textVar.value[6].value =
                            response.data.textFields.filter(val => val.key === "labels.subtitle").filter(val => val.languageCode === 'en-EN').length ?
                            response.data.textFields.filter(val => val.key === "labels.subtitle").filter(val => val.languageCode === 'en-EN')[0].value :
                            '';
                    }
                    if (response.data.textFields.filter(val => val.key.includes("labels.desc")).length) {
                        textVar.value[2].value =
                            response.data.textFields.filter(val => val.key.includes("labels.desc")).filter(val => val.languageCode === 'it-IT').length ?
                            response.data.textFields.filter(val => val.key.includes("labels.desc")).filter(val => val.languageCode === 'it-IT')[0].value :
                            '';
                        textVar.value[7].value =
                            response.data.textFields.filter(val => val.key.includes("labels.desc")).filter(val => val.languageCode === 'en-EN').length ?
                            response.data.textFields.filter(val => val.key.includes("labels.desc")).filter(val => val.languageCode === 'en-EN')[0].value :
                            '';
                    }
                    if (response.data.textFields.filter(val => val.key.includes("labels.practic")).length) {
                        textVar.value[3].value =
                            response.data.textFields.filter(val => val.key.includes("labels.practic")).filter(val => val.languageCode === 'it-IT').length ?
                            response.data.textFields.filter(val => val.key.includes("labels.practic")).filter(val => val.languageCode === 'it-IT')[0].value :
                            '';
                        textVar.value[8].value =
                            response.data.textFields.filter(val => val.key.includes("labels.practic")).filter(val => val.languageCode === 'en-EN').length ?
                            response.data.textFields.filter(val => val.key.includes("labels.practic")).filter(val => val.languageCode === 'en-EN')[0].value :
                            '';
                    }
                    if (response.data.textFields.filter(val => val.key.includes("labels.duration")).length) {
                        textVar.value[4].value =
                            response.data.textFields.filter(val => val.key.includes("labels.duration")).filter(val => val.languageCode === 'it-IT').length ?
                            response.data.textFields.filter(val => val.key.includes("labels.duration")).filter(val => val.languageCode === 'it-IT')[0].value :
                            '';
                        textVar.value[9].value =
                            response.data.textFields.filter(val => val.key.includes("labels.duration")).filter(val => val.languageCode === 'en-EN').length ?
                            response.data.textFields.filter(val => val.key.includes("labels.duration")).filter(val => val.languageCode === 'en-EN')[0].value :
                            '';
                    }
                }
                status.value = response.data.status.name;
                selItineraryTypesData.value = response.data.types;
            } catch (error) {
                getError.value = error;
                itinerariesEditData.value = null
            } finally {
                isLoading.value = false;
                cnt.value = 1;
            }
        };

        const fetchGroupset = async () => {
            isGroupsetFetch.value = true;
            try {
                const response = await axios.get(`${baseUrl}/v1.1/private/groupsets`, {
                    headers: {
                        Authorization: `Bearer ${token.value}`,
                    },
                });
                getError.value = null;
                groupsetData.value = response.data.results;
            } catch (error) {
                getError.value = error;
                groupsetData.value = null;
            } finally {
                isGroupsetFetch.value = false;
            }
        };

        const fetchData = async () => {
            isLoading.value = true;
            try {
                const response = await axios
                    .post(
                        `${baseUrl}/v1/private/itineraries/totals`, {}, {
                            headers: {
                                Authorization: `Bearer ${token.value}`,
                            },
                        }
                    );
                getError.value = null;
                switch (response.data.length) {
                    case 0:
                        totals.value = published.value = 0
                        break;
                    case 1:
                        totals.value = response.data[0].total;
                        response.data[0].status === "DRAFT" ? published.value = 0 : published.value = response.data[0].total
                        break;
                    default:
                        totals.value = response.data[0].total + response.data[1].total;
                        published.value = response.data.filter(val => val.status === 'PUBLISHED')[0].total;
                        break;
                }
            } catch (error) {
                getError.value = error;
                totals.value = published.value = null;
            } finally {
                isLoading.value = false;
            }
        };

        const selectedMusuemFunc = (selectedMuseums) => {
            selectMuseums.value = selectedMuseums.value;
        }

        const handleMarkedPath = (payload) => {
            markedPath.value = payload;
        }

        const handleTicket = (payload) => {
            ticketRequired.value = payload;
        }

        const handleTitle = (payload) => {
            payload.lang === 'it-IT' ? textVar.value[0] = {
                key: 'labels.title',
                value: payload.text,
                languageCode: 'it-IT'
            } : textVar.value[5] = {
                key: 'labels.title',
                value: payload.text,
                languageCode: 'en-EN'
            }
        }

        const handleSubTitle = (payload) => {
            payload.lang === 'it-IT' ? textVar.value[1] = {
                key: 'labels.subtitle',
                value: payload.text,
                languageCode: 'it-IT'
            } : textVar.value[6] = {
                key: 'labels.subtitle',
                value: payload.text,
                languageCode: 'en-EN'
            }
        }

        const handleDescription = (payload) => {
            payload.lang === 'it-IT' ? textVar.value[2] = {
                key: 'labels.description',
                value: payload.text,
                languageCode: 'it-IT'
            } : textVar.value[7] = {
                key: 'labels.description',
                value: payload.text,
                languageCode: 'en-EN'
            }
        }

        const handlePracticability = (payload) => {
            payload.lang === 'it-IT' ? textVar.value[3] = {
                key: 'labels.practicability',
                value: payload.text,
                languageCode: 'it-IT'
            } : textVar.value[8] = {
                key: 'labels.practicability',
                value: payload.text,
                languageCode: 'en-EN'
            }
        }

        const handleDuration = (payload) => {
            payload.lang === 'it-IT' ? textVar.value[4] = {
                key: 'labels.duration',
                value: payload.text,
                languageCode: 'it-IT'
            } : textVar.value[9] = {
                key: 'labels.duration',
                value: payload.text,
                languageCode: 'en-EN'
            }
        }

        const handleLang = (payload) => {
            langCode.value = (payload === "English") ? "en-EN" : "it-IT";
        }

        const isEmpty = (str) => {
            return (str === null || str === undefined || str.length <= 0) ? true : false;
        }

        const handleEdit = (statusVar) => {
            if (
                !isEmpty(textVar.value[0].value) && !isEmpty(textVar.value[2].value) && !isEmpty(textVar.value[5].value) &&
                !isEmpty(textVar.value[7].value) && !isEmpty(imagesData.value) && selectMuseums.value.length >= 2
            ) {
                axios.
                put(
                    `${baseUrl}/v1/private/itineraries/${props.editorId}`, {
                        status: statusVar,
                        name: textVar.value[0].value,
                        groupsetId: editGroupset.value.uuid,
                        textFields: [...textVar.value].map(val => ({
                            ...val
                        })),
                        images: [...imagesData.value].map(val => ({
                            ...val
                        }).uuid),
                        museums: [...selectMuseums.value].map(val => ({
                            ...val
                        }).uuid),
                        markedPath: markedPath.value,
                        ticketRequired: ticketRequired.value,
                        types: labelData.value.map(val => val.uuid)
                    }, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token.value}`,
                        },
                    }
                ).
                then(() => {
                    $toast.success("L'itinerario è stato aggiornato con successo.", {
                        position: 'top-right'
                    })
                    router.value.push({
                        name: 'Itineraries'
                    });
                }).
                catch((err) => {
                  getError.value = err;
                })
                return true;
            }
            validateText.value = [];
            if (isEmpty(textVar.value[0].value)) validateText.value.push('<b>Titolo in Italiano</b>');
            if (isEmpty(textVar.value[2].value)) validateText.value.push('<b>Descrizione in Italiano</b>');
            if (isEmpty(textVar.value[5].value)) validateText.value.push('<b>Titolo in Inglese</b>');
            if (isEmpty(textVar.value[7].value)) validateText.value.push('<b>Descrizione in Inglese</b>');
            if (isEmpty(imagesData.value)) validateText.value.push('<b>Immagini</b>');
            if (selectMuseums.value.length < 2) validateText.value.push('<b>Musei</b>');
            onShowModal();
        }

        const onMuseumOrderChange = (payload) => {
            selectMuseums.value = payload;
        }

        const onShowModal = () => {
            openModal(ValidateModal, {
                modalTitle: 'Avvertimento',
                modalContent: validateText.value
            })
        }

        const handleUpload = (payload) => {
            imagesData.value = payload;
        }

        const handleLabel = (payload) => {
            labelData.value = payload;
        }

        const handleGoBack = () => {
            router.value.go(-1);
        }

        watch(isGroupsetFetch, newValue => {
            if (!newValue) fetchDataById()
        })

        watch(groupField, newValue => {
            editGroupset.value = newValue;
            fetchMuseums(newValue);
            if (cnt.value === 2) selectMuseums.value = [];
        })

        onBeforeMount(() => {
            console.log('editorId', props.editorId);
            const data = localStorage.getItem('mi_cms-tokens');
            if (data === null || data === undefined || data === '') 
              return;
            else {
              token.value = JSON.parse(data).access;
            }
        })

        onMounted(() => {
            fetchData();
            fetchGroupset();
            fetchItinerariesTypes();
        })
        return {
            props,
            isLoading,
            getError,
            baseUrl,
            token,
            totals,
            userRole,
            published,
            selectMuseums,
            groupField,
            groupsetData,
            ticketRequired,
            markedPath,
            textVar,
            itinerariesEditData,
            museumAllData,
            imagesData,
            langCode,
            status,
            validateText,
            itineraryTypesData,
            selItineraryTypesData,
            selectedMusuemFunc,
            handleMarkedPath,
            handleTicket,
            handleTitle,
            handleSubTitle,
            handleDescription,
            handlePracticability,
            handleDuration,
            handleLang,
            handleUpload,
            handleEdit,
            handleLabel,
            onMuseumOrderChange,
            handleGoBack
        }
    }
}
</script>

<style>
.gx-4px {
    gap: 4px;
}

.arrow-button {
    color: var(--neutrali-grigio-scuro, #4C4C4C);
    /* caption */
    font-family: Titillium Web;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 171.429% */
}

.create-route-panel-body {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    align-content: flex-start;
    flex-shrink: 0;
    flex-wrap: wrap;
    gap: 32px;
    border-radius: 8px;
    border: 1px solid var(--neutrali-grigio-segnaposto, #8F8F8F);
}

p.text-overview {
    text-align: left;
}

.flex-1 {
    flex: 1;
}
</style>
