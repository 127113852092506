import PremialitaButton from "./components/Button.vue";
import PremialitaModal from "./components/BaseModal.vue";
import PremialitaImageUpload from "./components/ImageUpload/PremialitaImageUpload.vue";
import PremialitaEvidenceList from "./components/InEvidenceList.vue";
import PremialitaInput from "./components/Input.vue";
import PremialitaLanguage from "./components/Language.vue";
import PremialitaMuseumList from "./components/MuseumList.vue";
import PremialitaOfferPoint from "./components/PointComponent.vue";
import PremialitaSearchItem from "./components/SearchItem.vue";
import PremialitaTable from "./components/Table/Table.vue";
import PremialitaTextArea from "./components/TextArea.vue";
import PremialitaItineraryEdit from "./components/ItineraryEdit.vue";

const PremialitaComponents = {
    install(Vue) {
        Vue.component('PremialitaButton', PremialitaButton);
        Vue.component('PremialitaModal', PremialitaModal);
        Vue.component('PremialitaImageUpload', PremialitaImageUpload);
        Vue.component('PremialitaEvidenceList', PremialitaEvidenceList);
        Vue.component('PremialitaInput', PremialitaInput);
        Vue.component('PremialitaLanguage', PremialitaLanguage);
        Vue.component('PremialitaMuseumList', PremialitaMuseumList);
        Vue.component('PremialitaOfferPoint', PremialitaOfferPoint);
        Vue.component('PremialitaSearchItem', PremialitaSearchItem);
        Vue.component('PremialitaTable', PremialitaTable);
        Vue.component('PremialitaTextArea', PremialitaTextArea);
        Vue.component('PremialitaItineraryEdit', PremialitaItineraryEdit);
    }
}

export default PremialitaComponents;
