<template>
<div v-if="props.isOpen" class="modal-mask">
    <div class="modal-wrapper">
        <div class="modal-base-container" ref="target">
            <div class="modal-header">
                <slot name="header">
                    <h3>Modal Header</h3>
                </slot>
            </div>
            <div class="modal-body">
                <slot name="content">
                    <h3>Modal Content</h3>
                </slot>
            </div>
            <div class="modal-footer">
                <slot name="footer">
                    <div class="d-flex gap-s">
                        <PremialitaButton text="Conferma" type="primary" class="bg-primary-custom" :isDisable="false" @handleClick="onSubmit" />
                        <PremialitaButton text="Annulla" type="regular" class="bg-white" :isDisable="false" @handleClick="onClose" />
                    </div>
                </slot>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ref
} from "vue";
import {
    onClickOutside
} from "@vueuse/core";
import PremialitaButton from "./Button.vue";
export default {
    name: 'PremialitaModal',
    components: {
        PremialitaButton
    },
    props: {
        isOpen: {
            type: Boolean
        }
    },
    emits: ["modal-close", "submit"],
    setup(props, {
        emit
    }) {
        const target = ref(null);
        onClickOutside(target, () => emit('modal-close'));
        const onClose = () => emit('modal-close');
        const onSubmit = () => emit('submit');
        return {
            props,
            target,
            onClose,
            onSubmit
        }
    }
}
</script>

<style lang="scss">
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-y: auto;
}

.modal-base-container {
    margin: 150px auto;
    padding: 1rem 1.5rem;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}

.modal-body {
    margin-bottom: 16px;
}

.crop-vertical {
    height: 200px;
    aspect-ratio: 9 / 16;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.crop-horizontal {
    height: 100px;
    aspect-ratio: 4 / 3;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.bg-gray-light {
    background-color: #8f8f8f8f;
}

.bg-gray-100 {
    background-color: #f7fafc
}

.gap-s {
    gap: 0.25rem;
}

.gap-xs {
    gap: 0.125rem;
}

.crop-cover {
    width: 100%;

    aspect-ratio: 1064 / 1425;
    padding: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.crop-internal {
    width: 128px;
    aspect-ratio: 1125 / 1425;
    padding: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.crop-card {
    width: 100%;
    aspect-ratio: 1029 / 540;
    padding: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media screen and (min-width: 768px) {
    .modal-base-container {
        width: 850px;
        max-width: 850px;
    }
}
</style>
