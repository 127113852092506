<template>
  <div class="p-2 d-flex flex-row align-items-start search-bar-container">
    <img src="@/assets/icons/search.png" class="search-icon" @click="handleSearchStr" />
    <input class="search-text w-100 border-0" v-model="searchStr" placeholder="Cerca..." @keyup.enter="handleSearchStr" />
  </div>
</template>
<script>
import { ref } from 'vue'
export default {
  name: 'SearchBarComponent',
  emits: ['handleSearch'],
  setup(props, {emit}) {
    const searchStr = ref('');
    const handleSearchStr = () => {
      emit('handleSearch', searchStr);
    }
    // watch(searchStr, () => {
    //   handleSearchStr();
    // })
    return {
      searchStr,
      handleSearchStr
    }
  }
}
</script>

<style>
.search-bar-container {
  border-radius: 4px;
  border: 1px solid var(--neutrali-nero, #1A1A1A);
  background: #FFF;
  min-width: 264px;
  gap: 8px;
}

.search-text {
  color: var(--neutrali-grigio-segnaposto, #8F8F8F);
  /* label/semibold_sm */
  font-family: Titillium Web;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

input:focus {
  outline: none;
}

.search-icon {
  cursor: pointer;
}
</style>