<template>
  <div class="w-100">
    <div class="title-input">
      Seleziona i musei che fanno parte dell'itinerario
    </div>
    <div class="w-100 d-flex flex-column gap-16px">
      <div class="p-2 d-flex flex-row align-items-start search-bar-container">
        <img src="@/assets/icons/search.png" />
        <input class="search-text w-100 border-0" placeholder="Ricerca..." v-model="filterStr" />
      </div>
      <div class="length-input">
        {{ filterCnt }} musei selezionati
      </div>
      <div>
        <div class="w-full" v-for="item in showMuseumData" :key="item.uuid">
          <div class="d-flex flex-col museum-check" :class="item.status.id === 2 ? 'disabled' : ''">
            <div class="form-check">
              <input 
                class="form-check-input" 
                type="checkbox" 
                :checked="item.flag"
                @click="(event) => handleSelect(item)"
              >
              <label class="check-label ">
                {{ item.name }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <v-pagination
        v-model="page"
        :pages="pages"
        :range-size="1"
        active-color="#DCEDFF"
        @update:modelValue="updateHandler"
      />
    </div>
  </div>
</template>
<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { ref, watch } from 'vue';

export default {
  name: 'SearchItemComponent',
  components: {
    VPagination
  },
  props: {
    museumData: {
      type: Array
    }
  },
  setup(props, {emit}) {
    const filterMuseumData = ref([]);
    const showMuseumData = ref([]);
    const selectedMuseums = ref([]);
    const getMuseums = ref([]);
    const filterCnt = ref(0);
    const filterStr = ref('');
    const page = ref(1);
    const pages = ref(0);

    watch(() => props.museumData, newValue => {
      getMuseums.value = [...newValue].map(val => ({...val}));
      filterMuseumData.value = getMuseums.value;
      showMuseumData.value = getMuseums.value.slice((page.value - 1) * 10, page.value * 10);
      selectedMuseums.value = getMuseums.value.filter(val => val.flag === true);
      page.value = 1;
      pages.value = Math.ceil(getMuseums.value.length / 10);
    })

    const handleSelect = (select) => {
      select.flag = !select.flag;
      // select item
      const selectedItem = filterMuseumData.value.filter(item => item.uuid === select.uuid);
      
      // add item to selected list
      if (!selectedMuseums.value.length) selectedMuseums.value.push(selectedItem[0]);
      else {
        const uuids = selectedMuseums.value.map(item => item.uuid);
        if (uuids.includes(select.uuid)) 
          selectedMuseums.value = selectedMuseums.value.filter(item => item.uuid !== select.uuid);
        else selectedMuseums.value.push(selectedItem[0]); 
      }

      // check value change
      filterMuseumData.value = filterMuseumData.value.map(item => 
        item.uuid === select.uuid ? ({...item, flag: !item.flag}) : item
      );

      // call callback
      emit('selectedMusuemFunc', selectedMuseums);
      filterCnt.value = selectedMuseums.value.length;
    }

    watch(filterStr, (newValue) => {
      if (selectedMuseums.value.length) {
        filterMuseumData.value = getMuseums.value.
        sort((a, b) => {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        }).map(item1 => {
          const matchingItem = selectedMuseums.value.find(item2 => item1.uuid === item2.uuid);

          if (matchingItem) {
            return {...item1, flag: true}
          }
          return {...item1, flag: false};
        })
      } else {
        filterMuseumData.value = getMuseums.value.
        sort((a, b) => {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        }).map(item => ({...item, flag: false}))
      }

      filterMuseumData.value = 
        filterMuseumData.value.filter(val => val.name.toUpperCase().
          includes(newValue.toUpperCase()));
        pages.value = Math.ceil(filterMuseumData.value.length / 10);
        showMuseumData.value = filterMuseumData.value.slice((page.value - 1) * 10, page.value * 10);
        page.value = 1;
      })

    const updateHandler = () => {
      showMuseumData.value = filterMuseumData.value.slice((page.value - 1) * 10, page.value * 10).
        map(val => {
          const matchingItem = selectedMuseums.value.find(item => val.uuid === item.uuid);

          if (matchingItem) {
            return {...val, flag: true}
          }
          return {...val, flag: false};
        });
    }

    return {
      filterMuseumData,
      selectedMuseums,
      showMuseumData,
      filterCnt,
      filterStr,
      getMuseums,
      page,
      pages,
      handleSelect,
      updateHandler
    }
  }
}
</script>
<style>
.length-input {
  color: var(--neutrali-grigio-segnaposto, #8F8F8F);
  /* body/sans-sm */
  font-family: Titillium Web;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.museum-check.disabled {
  pointer-events: none;
  opacity: 0.4;
}
</style>