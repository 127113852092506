<template>
<div class="p-3">
    <button class="d-flex flex-row gx-4px border-0 bg-white" @click="handlePageModal">
        <img src="@/assets/icons/arrow-back.png" />
        <div class="arrow-button">INDIETRO</div>
    </button>

    <div class="d-flex flex-col w-100">
        <div class="d-flex flex-row w-100">
            <h1 class="text-title">Premialità Offerta</h1>
            <div class="ml-auto d-flex flex-row gap-16px">
                <premialita-button text="Salva bozza" type="regular" class="bg-white" :isDisable="false" @handleClick="() => handleEdit(editOfferData?.status?.name === 'DRAFT' ? 'DRAFT' : 'PUBLISHED')" />
                <premialita-button :text="editOfferData?.status?.name === 'DRAFT' ? 'Pubblica offerta' : 'RIMUOVI PUBBLICAZIONE'" type="primary" class="bg-primary-custom" :isDisable="false" @handleClick="() => handleEdit(editOfferData?.status?.name === 'DRAFT' ? 'PUBLISHED' : 'DRAFT')" />
            </div>
        </div>
    </div>

    <div class="mt-48px d-flex flex-row gap-16px ">
        <div class="create-route-panel-body flex-1 ">
            <div class="d-flex flex-row gap-16px w-100">
                <premialita-input v-if="langCode === 'it-IT'" title="Titolo" :max-number="50" :text="editOfferData.textFields.find(val => val.languageCode === 'it-IT' && val.key === 'labels.title').value" placeholder="Inserisci un titolo" language="it-IT" @handleText="handleTitle" />
                <premialita-input v-else title="Title" :max-number="50" :text="editOfferData.textFields.find(val => val.languageCode === 'en-EN' && val.key === 'labels.title').value" placeholder="Enter a title" language="en-EN" @handleText="handleTitle" />
                <premialita-input v-if="langCode === 'it-IT'" title="Sottotitolo" :max-number="50" :text="editOfferData.textFields.find(val => val.languageCode === 'it-IT' && val.key === 'labels.subtitle').value" placeholder="Inserisci un sottotitolo" language="it-IT" @handleText="handleSubTitle" />
                <premialita-input v-else title="Subtitle" :max-number="50" :text="editOfferData.textFields.find(val => val.languageCode === 'en-EN' && val.key === 'labels.subtitle').value" placeholder="Enter a subtitle" language="en-EN" @handleText="handleSubTitle" />
            </div>
            <div class="d-flex flex-row gap-16px w-100">
                <div class="flex-1">
                    <premialita-text-area v-if="langCode === 'it-IT'" title="Descrizione" :max-number="2000" :rows="8" :placeholder="'Inserisci una descrizione'" language="it-IT" :text="editOfferData.textFields.find(val => val.languageCode === 'it-IT' && val.key === 'labels.description').value" @handleText="handleDescription" />
                    <premialita-text-area v-else title="Description" :max-number="2000" :rows="8" :placeholder="'Enter a description'" language="en-EN" :text="editOfferData.textFields.find(val => val.languageCode === 'en-EN' && val.key === 'labels.description').value" @handleText="handleDescription" />
                </div>
                <div class="flex-1">
                    <premialita-image-upload :token="props.token" :baseUrl="props.baseUrl" :imageData="imagesData" @handleUpload="handleUpload" />
                </div>
            </div>
            <div class="w-100">
                <div class="title-input mb-2">Tipo di offerta</div>
                <div class="d-flex flex-row gap-8px align-items-center justify-content-between">
                    <div class="offer-type-select w-50">
                        <Multiselect :searchable="false" :show-labels="false" :allow-empty="false" track-by="name" label="name" placeholder="Seleziona il tipo" v-model="selectedOfferType" :options="offerTypes"></Multiselect>
                    </div>
                    <div v-if="selectedOfferType?.name === 'Sconto su biglietto'" class="mr-8">
                        <PointComponent title="Valore percentuale" type="discount" v-model:points="editOfferData.discount" :maxLength="100" />
                    </div>
                </div>
            </div>

            <div class="w-100 d-flex flex-row gap-8px align-items-center justify-content-between">
                <div class="w-50">
                    <div class="title-input">
                        Seleziona il raggruppamento inerente all'offerta
                    </div>
                    <div>
                        <Multiselect :searchable="false" :show-labels="false" :allow-empty="false" label="name" v-model="groupField" :options="groupsetData" :hideSelected="true" track-by="name" placeholder="Seleziona gruppo" @select="handleGroupSetChange"></Multiselect>
                    </div>
                </div>
                <div class="mr-8">
                    <PointComponent title="Numero di punti richiesti" type="point" v-model:points="editOfferData.points" :maxLength="2000" />
                </div>
            </div>

            <div v-if="isLoading" class="d-flex flex-row w-50">
                <Loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" color="#2D489D" loader="dots"></Loading>
            </div>
            <div v-show="!isLoading" class="d-flex flex-row gap-16px w-100">
                <div class="w-50">
                    <premialita-search-item @selectedMusuemFunc="selectedMusuemFunc" :museumData="museumsData" :baseUrl="props.baseUrl" />
                </div>
                <div class="w-50 px-3">
                    <premialita-museum-list :selectedList="selectMuseums" @onMuseumOrderChange="onMuseumOrderChange" />
                </div>
            </div>
        </div>
        <div>
            <premialita-language @handleLangCode="handleLangCode" @handleDel="handleDel" />
        </div>
    </div>
    <premialita-modal :isOpen="isValidateModal">
        <template #header>
            <h1 class="font-display-4">Avvertimento</h1>
        </template>
        <template #content>
            <h5>Per salvare l'offerta è necessario compilare i seguenti campi:</h5>
            <div v-for="(item, index) in validateText" :key="index" v-html="item" class="px-3"></div>
        </template>
        <template #footer>
            <premialita-button text="Conferma" type="primary" class="bg-primary-custom" :isDisable="false" @handleClick="handleValidateModal" />
        </template>
    </premialita-modal>
</div>
</template>

<script>
import {
    watch,
    toRef,
    ref,
    onMounted
} from 'vue';
import Multiselect from 'vue-multiselect';
import PointComponent from '@/components/PointComponent.vue';
import axios from 'axios';
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/css/index.css';
export default {
    name: 'EditOffer',
    components: {
        Multiselect,
        PointComponent,
        Loading
    },
    props: {
        token: {
            type: String,
            required: true
        },
        baseUrl: {
            type: String,
            required: true
        },
        editData: {
            type: Object
        },
        getGroupset: {
            type: Object
        }
    },
    emits: ['handlePageModal', 'handleReload'],
    setup(props, {
        emit
    }) {
        const isLoading = ref(false);
        const editOfferData = toRef(props, 'editData');
        const langCode = ref('it-IT');
        const offerTypes = [{
            name: 'Sconto su biglietto',
            value: 'PERCENTAGE'
        }, {
            name: 'Ingresso gratuito',
            value: 'FLAT'
        }];
        const selectedOfferType = ref(null);
        const imagesData = ref([]);
        const validateText = ref([]);
        const isValidateModal = ref(false);
        const groupField = ref(null);
        const museumsData = ref([]);
        const selectMuseums = ref([]);
        const groupsetData = toRef(props, 'getGroupset');

        const handlePageModal = () => {
            emit('handlePageModal')
        }

        const handleTitle = (payload) => {
            payload.lang === 'it-IT' ? editOfferData.value.textFields.find(val => val.languageCode === 'it-IT' && val.key === 'labels.title').value = payload.text : editOfferData.value.textFields.find(val => val.languageCode === 'en-EN' && val.key === 'labels.title').value = payload.text
        }

        const handleSubTitle = (payload) => {
            payload.lang === 'it-IT' ? editOfferData.value.textFields.find(val => val.languageCode === 'it-IT' && val.key === 'labels.subtitle').value = payload.text : editOfferData.value.textFields.find(val => val.languageCode === 'en-EN' && val.key === 'labels.subtitle').value = payload.text
        }

        const handleDescription = (payload) => {
            payload.lang === 'it-IT' ? editOfferData.value.textFields.find(val => val.languageCode === 'it-IT' && val.key === 'labels.description').value = payload.text : editOfferData.value.textFields.find(val => val.languageCode === 'en-EN' && val.key === 'labels.description').value = payload.text
        }

        const handleUpload = (payload) => {
            imagesData.value = payload;
        }

        const handleDiscountValue = (payload) => {
            editOfferData.value.discount = payload;
        }

        const handlePointsNo = (payload) => {
            editOfferData.value.points = payload;
        }

        const isEmpty = (str) => {
            return (str === null || str === undefined || str.length <= 0) ? true : false;
        }

        const fetchMuseums = async (groupset) => {
            isLoading.value = true;
            try {
                const res = await axios.get(`${props.baseUrl}/v1/private/museums`, {
                    params: {
                        groupsetId: groupset.uuid
                    },
                    headers: {
                        Authorization: `Bearer ${props.token}`
                    }
                });
                museumsData.value = res.data.map(val => ({
                    ...val,
                    flag: selectMuseums.value.map(item => item.uuid).includes(val.uuid)
                })).sort((a, b) => {
                    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });
            } catch (err) {
                console.log(err)
            } finally {
                isLoading.value = false
            }
        }

        const handleEdit = async (statusVar) => {
            console.log('--->>>>', editOfferData.value, '::', statusVar, ':::', imagesData.value)
            if (
                !isEmpty(editOfferData.value.textFields[0].value) && !isEmpty(editOfferData.value.textFields[1].value) && !isEmpty(editOfferData.value.textFields[2].value) &&
                !isEmpty(editOfferData.value.textFields[3].value) && !isEmpty(editOfferData.value.textFields[4].value) && !isEmpty(editOfferData.value.textFields[5].value) &&
                !isEmpty(imagesData.value) && selectMuseums.value.length >= 2 && !isEmpty(selectedOfferType.value)
            ) {
                isLoading.value = true;
                try {
                    await axios.put(
                        `${props.baseUrl}/v1/private/offers/${editOfferData.value.uuid}`, {
                            name: editOfferData.value.textFields.find(val => val.languageCode === 'it-IT' && val.key === 'labels.title').value,
                            status: statusVar,
                            type: selectedOfferType.value.value,
                            groupsetId: groupField.value.uuid,
                            museums: [...selectMuseums.value].map(val => val.uuid),
                            images: [...imagesData.value].map(val => val.uuid),
                            textFields: [...editOfferData.value.textFields.map(val => ({
                                ...val
                            }))],
                            points: editOfferData.value.points,
                            discount: selectedOfferType.value.name === "Ingresso gratuito" ? 0 : editOfferData.value.discount,
                            marked: false,
                            expiresAt: editOfferData.value.expiresAt
                        }, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${props.token}`,
                            },
                        }
                    )
                    handlePageModal();
                    emit('handleReload');
                } catch (err) {
                    console.log(err)
                } finally {
                    isLoading.value = false;
                }
                return true;
            }
            validateText.value = [];
            if (isEmpty(editOfferData.value.textFields.find(val => val.languageCode === 'it-IT' && val.key === 'labels.title').value)) validateText.value.push('<b>Titolo in Italiano</b>');
            if (isEmpty(editOfferData.value.textFields.find(val => val.languageCode === 'it-IT' && val.key === 'labels.subtitle').value)) validateText.value.push('<b>Sottotitolo in Italiano</b>');
            if (isEmpty(editOfferData.value.textFields.find(val => val.languageCode === 'it-IT' && val.key === 'labels.description').value)) validateText.value.push('<b>Descrizione in Italiano</b>');
            if (isEmpty(editOfferData.value.textFields.find(val => val.languageCode === 'en-EN' && val.key === 'labels.title').value)) validateText.value.push('<b>Titolo in Inglese</b>');
            if (isEmpty(editOfferData.value.textFields.find(val => val.languageCode === 'en-EN' && val.key === 'labels.subtitle').value)) validateText.value.push('<b>Sottotitolo in Inglese</b>');
            if (isEmpty(editOfferData.value.textFields.find(val => val.languageCode === 'en-EN' && val.key === 'labels.description').value)) validateText.value.push('<b>Descrizione in Inglese</b>');
            if (isEmpty(imagesData.value)) validateText.value.push('<b>Immagini</b>');
            if (isEmpty(selectedOfferType.value)) validateText.value.push('<b>Tipo di offerta</b>');
            if (selectMuseums.value.length < 2) validateText.value.push('<b>Musei</b>');
            isValidateModal.value = true;
        }

        const selectedMusuemFunc = (selectedMuseums) => {
            selectMuseums.value = [...selectedMuseums.value].map(val => ({
                ...val
            }));
        }

        const handleLangCode = (payload) => {
            langCode.value = payload === 'Italiano' ? 'it-IT' : 'en-EN';
        }

        const handleDel = async () => {
            isLoading.value = true;
            try {
                await axios
                    .delete(
                        `${props.baseUrl}/v1/private/offers/${editOfferData.value.uuid}`, {
                            headers: {
                                Authorization: `Bearer ${props.token}`,
                            },
                        }
                    )
                handlePageModal();
                emit('handleReload');
            } catch (err) {
                return err;
            } finally {
                isLoading.value = false;
            }
        }

        const handleValidateModal = () => {
            isValidateModal.value = false;
        }

        const onMuseumOrderChange = (payload) => {
            selectMuseums.value = payload;
        }

        const handleGroupSetChange = () => {
            selectMuseums.value = [];
        }

        watch(groupField, newValue => {
            fetchMuseums(newValue);
        })

        onMounted(() => {
            imagesData.value = editOfferData.value.images;
            selectedOfferType.value = {
                name: editOfferData.value.type.name === "FLAT" ? 'Ingresso gratuito' : 'Sconto su biglietto',
                value: editOfferData.value.type.name
            }
            groupField.value = editOfferData.value.groupset;
            selectMuseums.value = editOfferData.value.museums;
            fetchMuseums(groupField.value);
        })

        return {
            props,
            editOfferData,
            isLoading,
            langCode,
            isValidateModal,
            validateText,
            offerTypes,
            imagesData,
            selectedOfferType,
            groupField,
            groupsetData,
            selectMuseums,
            museumsData,
            onMuseumOrderChange,
            selectedMusuemFunc,
            handlePageModal,
            handleTitle,
            handleSubTitle,
            handleDescription,
            handleDiscountValue,
            handleUpload,
            handleEdit,
            handleLangCode,
            handleDel,
            handleValidateModal,
            handleGroupSetChange,
            handlePointsNo
        }
    }
}
</script>

<style>
.mr-4 {
    margin-right: 1rem;
}
.mr-8 {
    margin-right: 2rem;
}
</style>