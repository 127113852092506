<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="title-input">
      {{ props.title }}
    </div>
    <input class="w-100 main-input mt-1" 
      :maxlength="props.maxNumber"
      v-model="content" 
      :placeholder="props.placeholder" 
    />
    <div class="d-flex justify-content-end length-input mt-1">
      {{ content.length }} &nbsp; / &nbsp; {{ props.maxNumber }}
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
export default {
  name: 'InputComponent',
  props: {
    title: {
      type: String
    },
    text: {
      type: String
    },
    maxNumber: {
      type: Number
    },
    language: {
      type: String
    },
    placeholder: {
      type: String
    }
  },
  emits: ['handleText'],
  setup(props, {emit}) {
    const content = ref(props.text);

    watch(() => props.text, newValue => {
      content.value = newValue;
    })

    watch(content, newValue => {
      emit('handleText', {text: newValue, lang: props.language});
    })

    return {
      props,
      content
    }
  }
}
</script>

<style>
.title-input {
  color: var(--neutrali-grigio-scuro, #4C4C4C);
  font-family: Titillium Web;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.length-input {
  color: var(--neutrali-nero, #1A1A1A);
  font-family: Roboto Mono;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.main-input {
  border-radius: 4px;
  border: 1px solid var(--neutrali-grigio, #BCBCBC);
  background: var(--neutrali-bianco, #FFF);
  padding: 7px;

}
</style>
