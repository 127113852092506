<template>
<div class="d-flex p-3 bg-language d-flex flex-column gap-24px">
    <div class="d-flex flex-column gap-8px">
        <div class="language-title">
            Seleziona lingua
        </div>
        <Multiselect :searchable="false" :show-labels="false" :allow-empty="false" v-model="selectedLan" :options="optionArray" :hideSelected="true"></Multiselect>
    </div>
    <premialita-button text="Elimina elemento" type="remove" @handleClick="onDelModal" />
    <premialita-modal :isOpen="isDelModal" name="warning-modal" @modal-close="onModalClose" @submit="onDelSubmit">
        <template #header>
            <h1 class="font-display-4">Rimozione itinerario</h1>
        </template>
        <template #content>
            <h5>Stai per <u><b>rimuovere</b></u> un itinerario al momento visibile sull'app di Musei Italiani. Se procedi l'itinerario verrà eliminato e tutti i suoi dati andranno persi.<br />Vuoi procedere?</h5>
        </template>
    </premialita-modal>
</div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import {
    ref,
    watch
} from "vue";
export default {
    name: 'PremialitaLanguage',
    components: {
        Multiselect,
    },
    props: {},
    emits: ['handleLangCode', 'handleDel'],
    setup(props, {
        emit
    }) {
        const selectedLan = ref('Italiano');
        const optionArray = ['Italiano', 'English'];
        const isDelModal = ref(false);
        const onDelModal = () => {
            isDelModal.value = true;
        }
        const onModalClose = () => {
            isDelModal.value = false;
        }
        const onDelSubmit = () => {
            emit('handleDel')
        }

        watch(selectedLan, newValue => {
            emit('handleLangCode', newValue);
        })
        return {
            props,
            selectedLan,
            optionArray,
            isDelModal,
            onModalClose,
            onDelModal,
            onDelSubmit
        }
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,700;1,400;1,600&display=swap');

.bg-language {
    border-radius: 5px;
    background: var(--neutrali-grigio-chiaro, #EBEBEB);
}

.gap-24px {
    gap: 24px;
}

.language-title {
    color: var(--neutrali-nero, #1A1A1A);
    /* link/sans-bold */
    font-family: Titillium Web;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 150% */
}

.modal-header {
    padding: 32px 32px 0 32px !important;
    border-bottom: none !important;
}

.modal-title {
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 32px !important;
}

.modal-body {
    padding: 32px !important;
}

.modal-body>div {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
}

.modal-footer {
    padding: 0 32px 32px !important;
    justify-content: flex-start !important;
    border-top: none !important;
}

.modal-footer>button {
    border: 1px solid #2D489D;
    border-radius: 4px;
    padding: 16px 32px;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
}

.modal-footer>button.btn-primary {
    background-color: #2D489D;
    color: white;
}

.modal-footer>button.btn-secondary {
    background-color: white;
    color: #2D489D;
}

@media screen and (min-width: 576px) {
    .modal-dialog {
        max-width: 646px !important;
    }
}
</style>
