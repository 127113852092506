<template>
  <div v-if="museumData.length" class="title-input">
    Seleziona i musei che fanno parte dell'itinerario
  </div>
  <draggable
    tag="ul"
    :list="museumData"
    class="d-flex flex-column mt-1 p-3 gap-16px bg-musemu-list"
    handle=".handle"
    item-key="uuid"
    @change="onChange"
  >
    <template #item="{ element, index }">
      <li class="d-flex flex-row align-items-center">
        <div class="d-flex flex-row flex-1 align-items-center gap-24px">
          <div>{{ index + 1 }}.</div>
          <div class="d-flex flex-row align-items-center gap-12px">
            <img class="musuem-avatar" :src="element.imgThumb" :alt="element.imgThumbAlt" />
            <div class="museum-name-list">
              {{ element.name }}
            </div>
          </div>
        </div>
        <i class="handle drag-img">
          <img src="@/assets/icons/drag.png" />
        </i>
      </li>
    </template>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";
import { ref, watch } from 'vue';

export default {
  name: 'MuseumListComponent',
  components: { draggable },
  props: {
    selectedList: {
      type: Array
    },
  },
  emits: ['onMuseumOrderChange'],
  setup(props, {emit}) {
    const museumData = ref(props.selectedList);

    const onChange = () => {
      emit('onMuseumOrderChange', museumData.value);
    }

    watch(() => props.selectedList, newValue => {
      museumData.value = newValue;
    })
    return {
      museumData,
      onChange
    }
  }
}
</script>

<style>
.gap-12px {
  gap: 12px;
}

.museum-name-list {
  color: var(--neutrali-nero, #1A1A1A);
  font-family: Titillium Web;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 112.5% */
}

.bg-musemu-list {
  border-radius: 8px;
  background: var(--neutrali-grigio-chiarissimo, #FAFAFA);
}

.bg-musemu-list .musuem-avatar {
  width: 38px;
  height: 38px;
  border-radius: 100%;
}
</style>