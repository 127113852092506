<template>
  <div class="modal fade show d-block" tabindex="-1" role="dialog" raria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmeModalLongTitle">{{ props.modalTitle }}</h5>
        </div>
        <div class="modal-body">
          <Multiselect
            :searchable="false"
            :show-labels="false"
            :allow-empty="false"
            v-model="exportFormat"
            :options="actionArray"
            :hideSelected="true"
          ></Multiselect>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="handleModal">Conferma</button>
          <button type="button" class="btn btn-secondary" @click="hideModal">Annulla</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { closeModal } from 'jenesius-vue-modal';
import { ref } from 'vue';
export default ({
  name: "SelectModal",
  components: {
    Multiselect
  },
  props: {
    modalTitle: {
      type: String,
    },
  },
  emits: ['return'],
  setup(props, {emit}) {
    const actionArray = ['CSV'];
    const exportFormat = ref('CSV');

    const hideModal = () => {
      closeModal()
    }
    const handleModal = () => {
      emit('return', exportFormat.value)
    }
    return {
      props,
      actionArray,
      exportFormat,
      handleModal,
      hideModal
    }
  }
})
</script>