<template>
<div class="d-flex flex-row align-items-center py-4 ">
    <SearchBarComponent @handleSearch="handleSearch" />
    <div v-if="props.userRole && !isLoading" class="ml-3 px-3 py-2 min-w-224px">
        <Multiselect :searchable="false" :show-labels="false" v-model="searchField" :resetAfter="true" :options="[{
          name: 'metti in evidenza', $isDisabled: itemsSelected.filter(val => val.status === 'DRAFT').length ? true : false}, { name: 'rimuovi da evidenza' } ]" :disabled="itemsSelected.length ? false : true" placeholder="Seleziona un'azione" track-by="name" label="name"></Multiselect>
    </div>
</div>
<EasyDataTable v-model:items-selected="itemsSelected" :headers="headers" v-model:server-options="serverOptions" :server-items-length="pages" :items="items" :header-item-class-name="headerItemClassNameFunction" :body-row-class-name="bodyRowClassNameFunction" :body-item-class-name="bodyItemClassNameFunction" :loading="isLoading" alternating buttons-pagination must-sort @click-row="showRow">
    <template #item-name="{ Avatar, name, ALT, ID }">
        <div class="d-flex flex-row py-12px  align-items-center">
            <img class="avator" :src="Avatar" :alt="ALT" />
            <div class="text-16px ml-12px">{{ name }}</div>
            <div class="d-none">{{ ID }}</div>
        </div>
    </template>
    <template #item-status="{ status }">
        <div class="d-flex flex-row py-12px  align-items-center" :class="status === 'DRAFT' && 'evidence-disable' ">
            <StatusComponent :text="status" :type="status === 'PUBLISHED' ? 'primary' : 'disable'" />
        </div>
    </template>
    <template #item-markedPath="{ markedPath }">
        <div class="d-flex flex-row py-12px  align-items-center">
            <StatusComponent :text="markedPath" :type="markedPath === 'In evidenza' ? 'primary' : 'disable'" />
        </div>
    </template>
</EasyDataTable>
</template>

<script>
import {
    onMounted,
    ref,
    watch
} from "vue";
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import SearchBarComponent from "../components/SearchBar.vue";
import StatusComponent from "../components/Status.vue"
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import {
    useToast
} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

export default {
    name: 'TableComponent',
    components: {
        SearchBarComponent,
        StatusComponent,
        Multiselect,
        EasyDataTable: Vue3EasyDataTable
    },
    props: {
        baseUrl: {
            type: String
        },
        token: {
            type: String
        },
        userRole: {
            type: Boolean
        },
        totals: {
            type: Number
        }
    },
    emits: ["handleEdit"],
    setup(props, {
        emit
    }) {
        const publishItinerariesData = ref([]);
        const totalData = ref([]);
        const itemsSelected = ref([]);
        const searchField = ref(null);
        const items = ref([]);
        const isLoading = ref(false);
        const getError = ref(null);
        const pages = ref(0);
        const searchStr = ref("");

        const serverOptions = ref({
            page: 1,
            rowsPerPage: 15,
            sortBy: 'name',
            sortType: 'asc',
        });

        const $toast = useToast();

        const actionArray = ref([{
                name: "metti in evidenza",
            },
            {
                name: "rimuovi da evidenza"
            }
        ]);

        const headers = [{
                text: "NOME",
                value: "name",
                sortable: true
            },
            {
                text: "RAGGRUPPAMENTO",
                value: "groupset",
                sortable: true
            },
            {
                text: "ULTIMA MODIFICA",
                value: "updatedAt",
                sortable: true
            },
            {
                text: "STATUS",
                value: "status",
                sortable: true
            },
            {
                text: "IN EVIDENZA",
                value: "markedPath",
                sortable: true
            },
        ];

        const showRow = (item) => {
            emit('handleEdit', item);
        };

        const bodyRowClassNameFunction = (item) => {
            if (item.score < 60) return 'fail-row';
            return 'pass-row';
        };

        const bodyItemClassNameFunction = (column) => {
            if (column === 'score') return 'score-column';
            return '';
        };

        const headerItemClassNameFunction = (header) => {
            if (header.value === 'score') return 'score-column';
            return '';
        };

        const fetchData = async (activePage, orderBy = 'name', orderDir = 'asc', name = "") => {
            isLoading.value = true;
            try {
                const response = await axios
                    .get(`${props.baseUrl}/v1.1/private/itineraries`, {
                        params: {
                            page: activePage,
                            size: 15,
                            name: name,
                            orderBy: orderBy,
                            orderDir: orderDir
                        },
                        headers: {
                            Authorization: `Bearer ${props.token}`
                        }
                    });
                getError.value = null;
                totalData.value = response.data.results;
                pages.value = response.data.count;
                items.value = response.data.results.map(val =>
                    ({
                        name: val.name,
                        groupset: val.groupset.name,
                        updatedAt: val.updatedAt ? val.updatedAt.split('T')[0] + ' ' + val.updatedAt.split('T')[1].split('.')[0] : '',
                        status: val.status.name,
                        markedPath: val.markedPath ? 'In evidenza' : 'Non in evidenza',
                        ALT: val.images.length ? val.images[0].alt : 'image',
                        Avatar: val.images.length ?
                            `${props.baseUrl}/v1/public/image-files/` + val.images[0].uuid + '?size=thumb' : '',
                        ID: val.uuid
                    })
                )
            } catch (error) {
                getError.value = error;
                totalData.value = null;
                items.value = null;
            } finally {
                setTimeout(() => {
                    isLoading.value = false;
                }, 1000);
            }
        };

        const handleSearch = async (payload) => {
            searchStr.value = payload.value;
            fetchData(0, 'name', 'asc', payload.value);
        }

        const handleAction = async (temp, newValue) => {
            try {
                await Promise.all(temp.map(async val => {
                    await axios.
                    put(
                        `${props.baseUrl}/v1/private/itineraries/${val.ID}`, {
                            status: totalData.value.filter(item => item.uuid === val.ID)[0].status.name,
                            name: totalData.value.filter(item => item.uuid === val.ID)[0].name,
                            groupsetId: totalData.value.filter(item => item.uuid === val.ID)[0].groupset.uuid,
                            textFields: totalData.value.filter(item => item.uuid === val.ID)[0].textFields,
                            images: totalData.value.filter(item => item.uuid === val.ID)[0].images.length ?
                                totalData.value.filter(item => item.uuid === val.ID)[0].images.map(item => item.uuid) : [],
                            museums: totalData.value.filter(item => item.uuid === val.ID)[0].museums.length ?
                                totalData.value.filter(item => item.uuid === val.ID)[0].museums.map(item => item.uuid) : [],
                            ticketRequired: totalData.value.filter(item => item.uuid === val.ID)[0].ticketRequired,
                            markedPath: newValue === 'metti in evidenza' ? true : false
                        }, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${props.token}`,
                            },
                        }
                    )
                }))
                $toast.success("Successo dell'azione", {
                    position: 'top-right'
                })
                location.reload();
            } catch (error) {
                return false;
            }
        }

        const updateSearchHandler = (payload) => {
            items.value = [...publishItinerariesData.value].slice((payload - 1) * 15, payload * 15);
        }

        watch(searchField, (newValue) => {
            if (newValue === null) return false;
            let temp = [];
            if (itemsSelected.value.length) {
                if (newValue.name === 'metti in evidenza') {
                    temp = itemsSelected.value.filter(val => val.markedPath === 'Non in evidenza');
                } else {
                    temp = itemsSelected.value.filter(val => val.markedPath === 'In evidenza');
                }

                if (temp.length) {
                    handleAction(temp, newValue.name);
                }
            }
        })

        watch(serverOptions, (value) => {
            value.sortBy === 'groupset' ? fetchData(value.page - 1, 'groupset.name', value.sortType, searchStr.value) : fetchData(value.page - 1, value.sortBy, value.sortType, searchStr.value)
        }, {
            deep: true
        })

        onMounted(() => {
            fetchData(0);
        })

        return {
            serverOptions,
            itemsSelected,
            searchField,
            headers,
            items,
            actionArray,
            pages,
            isLoading,
            getError,
            props,
            bodyRowClassNameFunction,
            bodyItemClassNameFunction,
            headerItemClassNameFunction,
            handleSearch,
            showRow,
            updateSearchHandler,
        }
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style><style>
.easy-checkbox {
    width: 50px;
}

.vue3-easy-data-table__header>tr:first-child {
    width: 50px;
}

.pagination__rows-per-page {
    display: none !important;
}

.ml-3 {
    margin-left: 16px;
}

.min-w-224px {
    min-width: 224px;
}

.custom-icon {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 30px;
    background: url('@/assets/icons/expand.png') no-repeat right center;
}

.py-12px {
    padding-bottom: 12px;
    padding-top: 12px;
}

.text-16px {
    padding-bottom: 16px;
    padding-top: 16px;
}

.ml-12px {
    margin-left: 12px;
}

table img {
    width: 38px;
    height: 38px;
    border-radius: 100%;
}

.p-container .Pagination {
    margin-left: 85%;
}

/* table tbody tr:has(.evidence-disable) td:first-child div, 
table thead tr th:first-child div  {
  pointer-events: none;
  opacity: 0.5;
  display: none;
} */
</style>
