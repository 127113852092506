<template>
<div class="p-3">
    <button class="d-flex flex-row gx-4px border-0 bg-white" @click="handleGoBack">
        <img src="@/assets/icons/arrow-back.png" />
        <div class="arrow-button">INDIETRO</div>
    </button>

    <div class="d-flex flex-col w-100">
        <div class="d-flex flex-row w-100">
            <h1 class="text-title">Itinerari consigliati</h1>
            <div class="ml-auto d-flex flex-row gap-16px">
                <ButtonComponent text="SALVA BOZZA" type="regular" class="bg-white" :isDisable="false" @handleClick="handleDraft" />
                <ButtonComponent text="PUBBLICA ITINERARIO" type="disabled" class="bg-disabled" :isDisable="true" />
            </div>
        </div>
    </div>
    <div>
        <p class="text-overview">{{ published }} itinerari pubblicati su {{ totals }} presenti</p>
    </div>
    <div class="mt-48px d-flex flex-row gap-16px ">
        <div class="create-route-panel-body flex-1 ">
            <div class="d-flex flex-row gap-16px w-100">
                <InputComponent v-if="langCode === 'it-IT'" title="Titolo" :max-number="50" :text="textVar[0].value" placeholder="Inserisci un titolo" language="it-IT" @handleText="handleTitle" />
                <InputComponent v-else title="Title" :max-number="50" :text="textVar[5].value" placeholder="Enter a title" language="en-EN" @handleText="handleTitle" />
                <InputComponent v-if="langCode === 'it-IT'" title="Sottotitolo" :max-number="50" :text="textVar[1].value" placeholder="Inserisci un sottotitolo" language="it-IT" @handleText="handleSubTitle" />
                <InputComponent v-else title="Subtitle" :max-number="50" :text="textVar[6].value" placeholder="Enter a subtitle" language="en-EN" @handleText="handleSubTitle" />
            </div>
            <div class="d-flex flex-row gap-16px w-100">
                <TextAreaComponent v-if="langCode === 'it-IT'" title="Praticabilità" :max-number="500" :rows="4" :placeholder="'Inserisci una praticabilità'" language="it-IT" :text="textVar[3].value" @handleText="handlePracticability" />
                <TextAreaComponent v-else title="Practicability" :max-number="500" :rows="4" :placeholder="'Enter a Practicability'" language="en-EN" :text="textVar[8].value" @handleText="handlePracticability" />
                <TextAreaComponent v-if="langCode === 'it-IT'" title="Durata dell'itinerario" :max-number="500" :rows="4" :placeholder="'Inserisci una durata'" language="it-IT" :text="textVar[4].value" @handleText="handleDuration" />
                <TextAreaComponent v-else title="Duration of the route" :max-number="500" :rows="4" :placeholder="'Enter a duration'" language="en-EN" :text="textVar[9].value" @handleText="handleDuration" />
            </div>
            <div class="d-flex flex-row gap-16px w-100">
                <div class="flex-1">
                    <TextAreaComponent v-if="langCode === 'it-IT'" title="Descrizione" :max-number="2000" :rows="8" :placeholder="'Inserisci una descrizione'" language="it-IT" :text="textVar[2].value" @handleText="handleDescription" />
                    <TextAreaComponent v-else title="Description" :max-number="2000" :rows="8" :placeholder="'Enter a description'" language="en-EN" :text="textVar[7].value" @handleText="handleDescription" />
                </div>
                <div class="flex-1">
                    <ImageUploadComponent :token="token" :baseUrl="baseUrl" @handleUpload="handleUpload" />
                </div>
            </div>
            <div class="d-flex flex-row gap-16px w-100">
                <div class="flex-1">
                    <CheckerComponent title="Ticketing" label="L'itinerario prevede l’acquisto di un biglietto" @handleCheck="handleTicket" :isChecked="ticketRequired" />
                </div>
            </div>
            <div class="w-50 gap-8px">
                <div class="title-input">
                    Seleziona il raggruppamento inerente all'itinerario
                </div>
                <div v-if="isLoading">
                    <Loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" color="#2D489D" loader="dots"></Loading>
                </div>
                <div v-else-if="getError">{{ getError }}</div>
                <div v-else>
                    <Multiselect :searchable="false" :show-labels="false" :allow-empty="false" label="name" v-model="groupField" :options="groupsetData" :hideSelected="true" track-by="name"></Multiselect>
                </div>
            </div>
            <div class="d-flex flex-row w-100">
                <div class="w-50">
                    <SearchItemComponent @selectedMusuemFunc="selectedMusuemFunc" :museumData="museumsData" :baseUrl="baseUrl" />
                </div>
                <div class="w-50 px-3">
                    <MuseumListComponent :selectedList="selectMuseums" @onMuseumOrderChange="onMuseumOrderChange" />
                </div>
            </div>
        </div>
        <div class=" ">
            <LanguageComponent @handleLang="handleLang" @handleLabel="handleLabel" :token="token" :baseUrl="baseUrl" :itineraryTypesData="itineraryTypesData" />
        </div>
    </div>
    <widget-container-modal />
</div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import ButtonComponent from '../components/Button.vue';
import InputComponent from '../components/Input.vue';
import TextAreaComponent from '../components/TextArea.vue';
import ImageUploadComponent from '../components/ImageUpload.vue'
import CheckerComponent from '../components/Checker.vue'
import SearchItemComponent from '../components/SearchItem.vue'
import LanguageComponent from '../components/Language.vue'
import MuseumListComponent from '../components/MuseumList.vue';
import ValidateModal from '../components/ValidateModal.vue';
import {
    container,
    openModal
} from "jenesius-vue-modal";
import {
    ref,
    onMounted,
    watch,
    onBeforeMount
} from 'vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import {
    useToast
} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import axios from 'axios';

export default {
    name: 'AddRouteView',
    components: {
        ButtonComponent,
        InputComponent,
        TextAreaComponent,
        ImageUploadComponent,
        CheckerComponent,
        SearchItemComponent,
        LanguageComponent,
        MuseumListComponent,
        Multiselect,
        Loading,
        WidgetContainerModal: container
    },
    props: {
        router: {
            type: Object
        }
    },
    setup(props) {
        const groupsetData = ref([]);
        const selectMuseums = ref([]);
        const museumsData = ref([]);
        const groupField = ref({
            name: ''
        });
        const isLoading = ref(false);
        const getError = ref(null);
        const ticketRequired = ref(false);
        const langCode = ref('it-IT');
        const imagesData = ref([]);
        const validateText = ref([]);
        const itineraryTypesData = ref([]);
        const labelData = ref([]);

        const $toast = useToast();
        const token = ref('');
        const baseUrl = 'https://ssot.laculturapremia.it/api';
        const totals = ref(0);
        const userRole = ref(true);
        const published = ref(0);
        const router = ref(props.router);

        const textVar = ref([{
                key: 'labels.title',
                value: '',
                languageCode: 'it-IT'
            },
            {
                key: 'labels.subtitle',
                value: '',
                languageCode: 'it-IT'
            },
            {
                key: 'labels.description',
                value: '',
                languageCode: 'it-IT'
            },
            {
                key: 'labels.practicability',
                value: '',
                languageCode: 'it-IT'
            },
            {
                key: 'labels.duration',
                value: '',
                languageCode: 'it-IT'
            },
            {
                key: 'labels.title',
                value: '',
                languageCode: 'en-EN'
            },
            {
                key: 'labels.subtitle',
                value: '',
                languageCode: 'en-EN'
            },
            {
                key: 'labels.description',
                value: '',
                languageCode: 'en-EN'
            },
            {
                key: 'labels.practicability',
                value: '',
                languageCode: 'en-EN'
            },
            {
                key: 'labels.duration',
                value: '',
                languageCode: 'en-EN'
            }
        ]);

        const fetchMuseums = async (groupset) => {
            isLoading.value = true;
            try {
                const response = await axios
                    .get(`${baseUrl}/v1/private/museums`, {
                        params: {
                            groupsetId: groupset.uuid
                        },
                        headers: {
                            Authorization: `Bearer ${token.value}`
                        }
                    });
                getError.value = null;
                museumsData.value = response.data.map(val => ({
                    ...val,
                    flag: false
                })).sort((a, b) => {
                    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });
            } catch (error) {
                getError.value = error;
                museumsData.value = null;
            } finally {
                isLoading.value = false;
            }
        };

        const fetchItinerariesTypes = async () => {
            isLoading.value = true;
            try {
                const response = await axios.get(`${baseUrl}/v1/public/itinerary-types`);
                getError.value = null;
                itineraryTypesData.value = response.data;

            } catch (error) {
                getError.value = error;
                itineraryTypesData.value = null;
            } finally {
                isLoading.value = false;
            }
        }

        const fetchGroupset = async () => {
            isLoading.value = true;
            try {
                const response = await axios.get(`${baseUrl}/v1.1/private/groupsets`, {
                    headers: {
                        Authorization: `Bearer ${token.value}`,
                    },
                });
                getError.value = null;
                groupsetData.value = response.data.results;
                groupField.value = response.data.results[0];
            } catch (error) {
                getError.value = error;
                groupsetData.value = null;
                groupField.value = null;
            } finally {
                isLoading.value = false;
            }
        };

        const fetchData = async () => {
            isLoading.value = true;
            try {
                const response = await axios
                    .post(
                        `${baseUrl}/v1/private/itineraries/totals`, {}, {
                            headers: {
                                Authorization: `Bearer ${token.value}`,
                            },
                        }
                    );
                getError.value = null;
                switch (response.data.length) {
                    case 0:
                        totals.value = published.value = 0
                        break;
                    case 1:
                        totals.value = response.data[0].total;
                        response.data[0].status === "DRAFT" ? published.value = 0 : published.value = response.data[0].total
                        break;
                    default:
                        totals.value = response.data[0].total + response.data[1].total;
                        published.value = response.data.filter(val => val.status === 'PUBLISHED')[0].total;
                        break;
                }
            } catch (error) {
                getError.value = error;
                totals.value = published.value = null;
            } finally {
                isLoading.value = false;
            }
        };

        const selectedMusuemFunc = (selectedMuseums) => {
            selectMuseums.value = [...selectedMuseums.value].map(val => ({
                ...val
            }));
        }

        const handleTicket = (payload) => {
            ticketRequired.value = payload;
        }

        const handleTitle = (payload) => {
            payload.lang === 'it-IT' ? textVar.value[0] = {
                ...textVar.value[0],
                value: payload.text
            } : textVar.value[5] = {
                ...textVar.value[5],
                value: payload.text
            }
        }

        const handleSubTitle = (payload) => {
            payload.lang === 'it-IT' ? textVar.value[1] = {
                ...textVar.value[1],
                value: payload.text
            } : textVar.value[6] = {
                ...textVar.value[6],
                value: payload.text
            }
        }

        const handleDescription = (payload) => {
            payload.lang === 'it-IT' ? textVar.value[2] = {
                ...textVar.value[2],
                value: payload.text
            } : textVar.value[7] = {
                ...textVar.value[7],
                value: payload.text
            }
        }

        const handlePracticability = (payload) => {
            payload.lang === 'it-IT' ? textVar.value[3] = {
                ...textVar.value[3],
                value: payload.text
            } : textVar.value[8] = {
                ...textVar.value[8],
                value: payload.text
            }
        }

        const handleDuration = (payload) => {
            payload.lang === 'it-IT' ? textVar.value[4] = {
                ...textVar.value[4],
                value: payload.text
            } : textVar.value[9] = {
                ...textVar.value[9],
                value: payload.text
            }
        }

        const handleLang = (payload) => {
            langCode.value = (payload === "English") ? "en-EN" : "it-IT";
        }

        const handleUpload = (payload) => {
            imagesData.value = payload;
        }

        const onShowModal = () => {
            openModal(ValidateModal, {
                modalTitle: 'Avvertimento',
                modalContent: validateText.value
            })
        }

        const onMuseumOrderChange = (payload) => {
            selectMuseums.value = payload;
        }

        const handleLabel = (payload) => {
            labelData.value = payload;
        }

        const isEmpty = (str) => {
            return (str === null || str === undefined || str.length <= 0) ? true : false;
        }

        const handleDraft = () => {
            if (
                !isEmpty(textVar.value[0].value) && !isEmpty(textVar.value[2].value) && !isEmpty(textVar.value[5].value) &&
                !isEmpty(textVar.value[7].value) && !isEmpty(imagesData.value) && selectMuseums.value.length >= 2
            ) {
                isLoading.value = true;
                axios.
                post(
                    `${baseUrl}/v1/private/itineraries`, {
                        status: 'DRAFT',
                        markedPath: false,
                        ticketRequired: ticketRequired.value,
                        museums: [...selectMuseums.value].map(val => val.uuid),
                        images: [...imagesData.value].map(val => val.uuid),
                        name: textVar.value[0].value,
                        groupsetId: groupField.value.uuid,
                        textFields: [...textVar.value].map(val => ({
                            ...val
                        })),
                        types: labelData.value.map(val => val.uuid)
                    }, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token.value}`,
                        },
                    }
                ).
                then(() => {
                    $toast.success("L'itinerario è stato salvato con successo.", {
                        position: 'top-right'
                    });

                    router.value.push({
                        name: 'Itineraries'
                    });
                }).
                catch((err) => {
                    getError.value = err;
                }).finally(() => {
                    isLoading.value = false;
                });
                return true;
            }
            validateText.value = [];
            if (isEmpty(textVar.value[0].value)) validateText.value.push('<b>Titolo in Italiano</b>');
            if (isEmpty(textVar.value[2].value)) validateText.value.push('<b>Descrizione in Italiano</b>');
            if (isEmpty(textVar.value[5].value)) validateText.value.push('<b>Titolo in Inglese</b>');
            if (isEmpty(textVar.value[7].value)) validateText.value.push('<b>Descrizione in Inglese</b>');
            if (isEmpty(imagesData.value)) validateText.value.push('<b>Immagini</b>');
            if (selectMuseums.value.length < 2) validateText.value.push('<b>Musei</b>');
            onShowModal();
        }

        const handleGoBack = () => {
            router.value.go(-1);
        }

        watch(groupField, newValue => {
            fetchMuseums(newValue)
            selectMuseums.value = [];
        })

        onBeforeMount(() => {
            const data = localStorage.getItem('mi_cms-tokens');
            if (data === null || data === undefined || data === '') 
              return;
            else {
              token.value = JSON.parse(data).access;
            }
        })

        onMounted(() => {
            fetchData();
            fetchGroupset();
            fetchItinerariesTypes();
        })
        return {
            isLoading,
            getError,
            selectMuseums,
            baseUrl,
            groupField,
            groupsetData,
            ticketRequired,
            langCode,
            published,
            totals,
            userRole,
            museumsData,
            textVar,
            validateText,
            itineraryTypesData,
            token,
            selectedMusuemFunc,
            handleTicket,
            handleLabel,
            handleDraft,
            handleTitle,
            handleSubTitle,
            handleDescription,
            handlePracticability,
            handleDuration,
            handleLang,
            handleUpload,
            onMuseumOrderChange,
            handleGoBack
        }
    }
}
</script>

<style>
.gx-4px {
    gap: 4px;
}

.arrow-button {
    color: var(--neutrali-grigio-scuro, #4C4C4C);
    /* caption */
    font-family: Titillium Web;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 171.429% */
}

.create-route-panel-body {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    align-content: flex-start;
    flex-shrink: 0;
    flex-wrap: wrap;
    gap: 32px;
    border-radius: 8px;
    border: 1px solid var(--neutrali-grigio-segnaposto, #8F8F8F);
}

p.text-overview {
    text-align: left;
}

.flex-1 {
    flex: 1;
}

img {
    display: block;
}

.label-left {
    padding-right: 0 !important;
}

.label-right {
    padding-left: 0 !important;
}
</style>
