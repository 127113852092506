<template>
<div class="w-100">
    <div class="title-input">
        {{ props.title }}
    </div>
    <div class="d-flex flex-col">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="checkValue" :disabled="props.disable" />
            <label class="check-label">
                {{ props.label }}
            </label>
        </div>
    </div>
</div>
</template>

  
<script>
import {
    ref,
    watch
} from 'vue';
export default {
    name: 'ChekerComponent',
    props: {
        title: {
            type: String
        },
        label: {
            type: String
        },
        isChecked: {
            type: Boolean
        },
        disable: {
            type: Boolean
        }
    },
    setup(props, {
        emit
    }) {
        const checkValue = ref(false);

        watch(() => props.isChecked, newValue => {
            checkValue.value = newValue
        })

        watch(checkValue, newValue => {
            emit('handleCheck', newValue);
        })

        return {
            props,
            checkValue,
        }
    }
}
</script>
  
<style>
.check-label {
    color: var(--color-text-base, #1A1A1A);

    /* body/sans-lg */
    font-family: Titillium Web;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 155.556% */
}
</style>
