<template>
  <multiselect 
    v-model="selectedLabel" 
    :options="labelTypesData.map(val => {
        const tmp = val.textFields.filter(subVal => subVal.languageCode === languageCode)[0];
        return {uuid: val.uuid, ...tmp};
      })" 
    :multiple="true" 
    placeholder="Cerca label" 
    track-by="uuid" 
    label="value"
    :showLabels="false"
  >
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { ref, watch } from 'vue';
export default {
    name: "LabelMultiSelect",
    components: {
      Multiselect
    },
    props: {
      labelData: {
        type: Array,
        default: ref([])
      },
      selectedLan: {
        type: String,
        default: 'Italiano'
      },
      selectedLabelData: {
        type: Array
      }
    },
    emits: ['handleLabel'],
    setup(props, {emit}) {
      const labelTypesData = ref(props.labelData);
      const selectedLabel = ref([]);
      const languageCode = ref("it-IT");

      watch(selectedLabel, newValue => {
        emit('handleLabel', newValue.map(val => ({...val})));
      })

      watch(() => props.selectedLan, newValue => {
        languageCode.value = newValue === "Italiano" ? "it-IT" : "en-EN";
        if (selectedLabel.value.length) {
          const selUuids = selectedLabel.value.map(val => val.uuid);
          const tmp = labelTypesData.value.filter(val => selUuids.includes(val.uuid)).
            map(val => {
              const temp = val.textFields.filter(subVal => subVal.languageCode === languageCode.value)[0];
              return {uuid: val.uuid, ...temp};
            })
          selectedLabel.value = tmp;
        }
      })

      watch(() => props.labelData, newValue => {
        labelTypesData.value = newValue;
      })

      watch(() => props.selectedLabelData, newValue => {
        selectedLabel.value = newValue.map(val => {
          const temp = val.textFields.filter(subVal => subVal.languageCode === languageCode.value)[0];
          return {uuid: val.uuid, ...temp};
        });
      })

      return {
        labelTypesData,
        selectedLabel,
        languageCode,
        props
      }
    }
}
</script>

<style scoped>
.multiselect {
  width: 230px;
  max-width: 230px;
}
</style>