<template>
<div class="d-flex flex-column gap-8px">
    <div class="image-length-text">
        Immagini {{ imagesData.length }} / 5
    </div>
    <div v-if="isLoading">
        <VueElementLoading :active="isLoading" spinner="spinner" color="#2D489D" />
    </div>
    <div v-else class="d-flex flex-wrap gap-8px">
        <draggable :list="imagesData" class="d-flex flex-wrap bg-white gap-16px p-0" handle=".handle">
            <div v-for="(element) in imagesData" :key="element.uuid" class="d-flex flex-column align-items-center gap-8px">
                <div class="home-cropped-image" @click="() => onEditModal(element)">
                    <div class="crop-internal">
                        <img :src="`${props.baseUrl}/v1/public/image-files/${element.uuid}?size=full`" :alt="element.alt" />
                    </div>
                    <div class="home-cropped-image-overlay">
                        <div>
                            <PencilSVG />
                        </div>
                    </div>
                </div>
                <div class="drag-img d-flex">
                    <div class="handle"><img src="@/assets/icons/drag.png" /></div>
                </div>
            </div>
        </draggable>
        <label v-if="imagesData.length < 5" for="image-upload">
            <div class="bg-add-image min-w-128px min-h-128px w-20 d-flex flex-column justify-content-center align-items-center">
                <img src="@/assets/icons/add.png" />
                <div class="add-image-text">Carica foto</div>
            </div>
            <input type="file" accept="image/*" id="image-upload" ref="imgRef" @change="handleImgselect" multiple />
        </label>
    </div>
</div>
<premialita-modal :isOpen="isModalOpened" @modal-close="closeBModal" @submit="submitHandler" name="upload-modal">
    <template #header>
        <h1 class="font-display-4">Upload immagine</h1>
    </template>
    <template #content>
        <div class="d-flex flex-column">
            <p>Dimensione massima del file: 5MB</p>
            <div class="row p-4 bg-gray-light rounded">
                <div v-if="isLoading" class="col-7">
                    <VueElementLoading :active="isLoading" spinner="spinner" color="#2D489D" />
                </div>
                <UiImageEditor v-else class="col-7" :img="previewMap[0]" @imgCroppedChanged="handleImgCropped" />
                <div class="col-5 d-flex gap-xs flex-column">
                    Anteprime in app:
                    <div class="row">
                        <div class="col-6">
                            <div class="crop-cover">
                                <img :src="imgCropped" alt="">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="crop-card">
                                <img :src="imgCropped" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="mt-4">
                        <premialita-input title="Testo alternativo" :max-number="50" :text="imgAltText[0].value" placeholder="Inserisci il testo alternativo" language="it-IT" @handleText="handleItAlt" />
                        <premialita-input title="Alt Text" :max-number="50" :text="imgAltText[1].value" placeholder="Enter Alt Text" language="en-En" @handleText="handleEnAlt" />
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template #footer>
        <div class="d-flex gap-8px align-items-center ml-auto">
            <premialita-button text="Conferma" type="regular" class="bg-white" :isDisable="isLoading" @handleClick="submitHandler" />
            <premialita-button text="Sostituisci" type="regular" class="bg-white" :isDisable="isLoading" @handleClick="() => triggerFileUpload(false)" />
            <premialita-button text="Annulla" type="primary" class="bg-primary-custom" :isDisable="isLoading" @handleClick="closeBModal" />
        </div>
    </template>
</premialita-modal>
<premialita-modal :isOpen="isErrorModal" @modal-close="() => isErrorModal = false" name="error-modal">
    <template #header>
        <h1 class="font-display-4">
            Errore di caricamento dell'immagine
        </h1>
    </template>
    <template #content>
        <h5>Il file dell'immagine supera la massima dimensione consentita</h5>
    </template>
    <template #footer>
        <button type="button" class="btn btn-primary" @click="() => isErrorModal = false">Conferma</button>
    </template>
</premialita-modal>
<premialita-modal :isOpen="isEditModalOpened" @modal-close="onCloseEditModal" @submit="handleEditImage" name="edit-modal">
    <template #header>
        <h1 class="font-display-4">
            Immagine modale
        </h1>
    </template>
    <template #content>
        <div class="d-flex flex-column">
            <p>Dimensione massima del file: 5MB</p>
            <div class="row p-4 bg-gray-light rounded">
                <div v-if="isLoading" class="col-7">
                    <VueElementLoading :active="isLoading" spinner="spinner" color="#2D489D" />
                </div>
                <UiImageEditor v-else class="col-7" :img="editImg.url" @imgCroppedChanged="handleImgCropped" />
                <div class="col-5 d-flex gap-xs flex-column">
                    Anteprime in app:
                    <div v-if="isLoading" class="row h-100 mb-5 mt-5">
                        <VueElementLoading :active="isLoading" spinner="spinner" color="#2D489D" />
                    </div>
                    <div v-else class="row">
                        <div class="col-6">
                            <div class="crop-cover">
                                <img :src="imgCropped" alt="">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="crop-card">
                                <img :src="imgCropped" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="mt-4">
                        <premialita-input title="Testo alternativo" :max-number="50" :text="imgAltText[0].value" placeholder="Inserisci il testo alternativo" language="it-IT" @handleText="handleItAlt" />
                        <premialita-input title="Alt Text" :max-number="50" :text="imgAltText[1].value" placeholder="Enter Alt Text" language="en-En" @handleText="handleEnAlt" />
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template #footer>
        <div class="d-flex gap-8px align-items-center ml-auto">
            <premialita-button text="Conferma" type="regular" class="bg-white" :isDisable="isLoading" @handleClick="handleEditImage" />
            <premialita-button text="Sostituisci" type="regular" class="bg-white" :isDisable="isLoading" @handleClick="() => triggerFileUpload(true)" />
            <premialita-button text="Elimina" type="primary" class="bg-red border-red" :isDisable="isLoading" @handleClick="handleRemoveImage" />
            <premialita-button text="Annulla" type="primary" class="bg-primary-custom" :isDisable="isLoading" @handleClick="onCloseEditModal" />
        </div>
    </template>
</premialita-modal>
</template>

<script>
import {
    ref,
    watch,
} from 'vue';
import axios from 'axios';
import {
    useFileUpdate
} from '../../utility/useFileUpdate';
import Compressor from 'compressorjs';
import UiImageEditor from './UiImageEditor.vue';
import 'vue-loading-overlay/dist/css/index.css';
import {
    VueDraggableNext
} from 'vue-draggable-next'
import VueElementLoading from 'vue-element-loading';
import PencilSVG from "./PencilSVG.vue";

export default {
    name: "PremialitaImageUpload",
    components: {
        UiImageEditor,
        VueElementLoading,
        PencilSVG,
        draggable: VueDraggableNext
    },
    props: {
        token: {
            type: String
        },
        baseUrl: {
            type: String
        },
        imageData: {
            type: Array
        }
    },
    emits: ['handleUpload'],
    setup(props, {
        emit
    }) {
        const {
            setFile,
            previewMap
        } = useFileUpdate();

        const imagesData = ref([]);
        const isLoading = ref(false);
        const getError = ref(null);
        const imgCropped = ref("");
        const isModalOpened = ref(false);
        const isErrorModal = ref(false);
        const imgAltText = ref([{
                key: "Alt-It",
                value: "",
                languageCode: "it-IT"
            },
            {
                key: "Alt-En",
                value: "",
                languageCode: "en-EN"
            },
        ]);
        const editImg = ref({});
        const isEditModalOpened = ref(false);
        const flagEdit = ref(false);
        const imgRef = ref(null);

        watch(() => props.imageData, newValue => {
            imagesData.value = newValue;
        })

        watch(imagesData, newValue => {
            emit('handleUpload', newValue);
        })

        const handleImgselect = (event) => {
            getError.value = null;
            setFile(event.target.files)
            imgCropped.value = previewMap.value[0];
            flagEdit.value ? handleEditSubstitute() : openBModal();
        }

        const handleEditSubstitute = () => {
            editImg.value.url = imgCropped.value;
            isEditModalOpened.value = true;
        }

        const handleImgCropped = (payload) => {
            imgCropped.value = payload;
        };

        const base64ToBlob = (base64, mimeType = '') => {
            // Decode base64 string
            const byteCharacters = atob(base64);

            // Create an array to store the bytes
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            // Create a blob from the byte array
            const blob = new Blob([byteArray], {
                type: mimeType
            });

            return blob;
        };

        const handleEditImage = () => {
            const filteredImgs = imagesData.value.filter(val => val.uuid !== editImg.value.id).map(val => ({
                ...val
            }));
            const file = DataURIToBlob(imgCropped.value);
            if (!file) {
                return;
            }

            new Compressor(file, {
                quality: 0.6,
                convertSize: 20000,
                async success(result) {
                    isLoading.value = true;
                    try {
                        let formData = new FormData();
                        formData.append('file', result, result.name);
                        const res = await axios.post(`${props.baseUrl}/v1/private/image-files`, formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: `Bearer ${props.token}`
                            }
                        });

                        filteredImgs.push(...res.data);
                        imagesData.value = filteredImgs;
                        flagEdit.value = false;
                        emit('handleUpload', imagesData.value);
                    } catch (err) {
                        getError.value = err;
                        isErrorModal.value = true;
                    } finally {
                        isEditModalOpened.value = false;
                        isLoading.value = false;
                    }

                },
            })
        }

        const handleRemoveImage = () => {
            const filteredImgs = imagesData.value.filter(val => val.uuid !== editImg.value.id).map(val => ({
                ...val
            }));
            imagesData.value = filteredImgs;
            emit('handleUpload', imagesData.value);
            isEditModalOpened.value = false;
            flagEdit.value = false;
        }

        const onEditModal = async (payload) => {
            editImg.value.id = payload.uuid;
            isEditModalOpened.value = true;
            isLoading.value = true;
            try {
                const res = await axios.get(`${props.baseUrl}/v1/public/image-files/${payload.uuid}?size=full`, {
                    headers: {
                        Authorization: `Bearer ${props.token}`
                    },
                    responseType: 'arraybuffer'
                });

                const base64 = btoa(
                    new Uint8Array(res.data).reduce(
                        (data, byte) => data + String.fromCharCode(byte),
                        ''
                    ),
                );

                const blob = base64ToBlob(base64, 'image/jpeg');

                const url = URL.createObjectURL(blob);

                editImg.value.url = url;
            } catch (err) {
                console.log(err);
            } finally {
                isLoading.value = false;
            }
        }

        const openBModal = () => {
            isModalOpened.value = true;
        }

        const closeBModal = () => {
            isModalOpened.value = false;
        }

        const onCloseEditModal = () => {
            isEditModalOpened.value = false;
            flagEdit.value = false;
        }

        const triggerFileUpload = (payload) => {
            imgRef.value.click();
            flagEdit.value = payload;
        }

        const DataURIToBlob = (dataURI) => {
            const splitDataURI = dataURI.split(',')
            const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
            const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

            const ia = new Uint8Array(byteString.length)
            for (let i = 0; i < byteString.length; i++)
                ia[i] = byteString.charCodeAt(i)

            return new Blob([ia], {
                type: mimeString
            })
        }

        const submitHandler = () => {
            const file = DataURIToBlob(imgCropped.value);
            if (!file) {
                return;
            }
            // image compress
            new Compressor(file, {
                quality: 0.6,
                convertSize: 20000,
                success(result) {
                    let formData = new FormData();
                    formData.append('file', result, result.name);
                    isLoading.value = true;
                    axios.post(`${props.baseUrl}/v1/private/image-files`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Bearer ${props.token}`
                        }
                    }).
                    then(res => {
                        getError.value = null;
                        imagesData.value.push(...res.data);
                        emit('handleUpload', imagesData.value);
                    }).
                    catch(error => {
                        getError.value = error;
                        isErrorModal.value = true;
                    }).
                    finally(() => {
                        isLoading.value = false;
                        closeBModal();
                    })
                },
                error(err) {
                    getError.value = err;
                }
            })
        }

        const handleItAlt = (payload) => {
            console.log('It-Alt', payload);
        }

        const handleEnAlt = (payload) => {
            console.log('En-Alt', payload);
        }

        return {
            props,
            isLoading,
            isErrorModal,
            isModalOpened,
            isEditModalOpened,
            editImg,
            imgRef,
            getError,
            flagEdit,
            imgAltText,
            imagesData,
            imgCropped,
            previewMap,
            submitHandler,
            onEditModal,
            openBModal,
            closeBModal,
            onCloseEditModal,
            triggerFileUpload,
            handleRemoveImage,
            handleEditImage,
            handleImgCropped,
            handleItAlt,
            handleEnAlt,
            handleImgselect
        }
    }
}
</script>

<style>
.gap-8px {
    gap: 8px;
}

.image-length-text {
    color: var(--neutrali-grigio-scuro, #4C4C4C);
    /* label/semibold_sm */
    font-family: Titillium Web;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 171.429% */
}

#image-upload {
    display: none;
}

.w-20 {
    width: 20%;
}

.bg-add-image {
    background: var(--accent-blu-light, #DFE5F6);
    cursor: pointer;
}

.modal-footer input {
    display: none;
}

.add-image-text {
    color: var(--accent-blu-accent, #2D489D);
    text-align: center;
    font-family: Titillium Web;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.drag-container img {
    width: 38px !important;
    height: 38px !important;
}

/* .img-show-container:nth-child(3) ~ .img-show-container {
  display: none;
} */

.img-show-container img,
.img-container img {
    width: 128px;
    height: 128px;
    object-fit: contain;
}

.drag-img {
    gap: 12px;
}

.drag-img>div {
    cursor: pointer;
}

.drag-img img {
    width: 24px;
    height: 24px;
}

.evidence-avatar {
    width: 38px;
    height: 38px;
    border-radius: 100%;
}

.img-container>div>div:first-child {
    flex: 0 0 25%;
    max-width: 25%;
}

.min-w-128px {
    min-width: 128px;
}

.min-h-128px {
    min-height: 128px;
}

.gap-16px {
    column-gap: 16px;
}

.py-16px {
    padding-top: 16px;
    padding-bottom: 16px;
}

.font-size-24px {
    font-size: 24px;
    font-weight: 600;
    padding-left: 32px;
}

.home-cropped-image {
    position: relative;
    cursor: pointer;
}

.home-cropped-image-overlay {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.4);
    transition: all 0.3s;
}

.home-cropped-image:hover>.home-cropped-image-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-cropped-image:hover>.home-cropped-image-overlay>div {
    width: 1rem;
    height: 1rem;
}
</style>
