<template>
<div>
    <cropper :src="props.img" @change="change" ref="cropperRef" />
    <UiImageSlider :zoom="zoom" @change="onZoom" />
</div>
</template>

<script>
import {
    ref
} from 'vue';
import UiImageSlider from './UiImageSlider.vue';
import {
    Cropper
} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import 'vue-advanced-cropper/dist/theme.classic.css';

export default {
    name: "UiImageEditor",
    components: {
        UiImageSlider,
        Cropper
    },
    props: {
        img: {
            type: String
        }
    },
    emits: ['imgCroppedChanged'],
    setup(props, {
        emit
    }) {
        const zoom = ref(0);
        const cropperRef = ref();
        let imgCropped;

        const change = ({
            canvas
        }) => {
            imgCropped = canvas.toDataURL()
            emit('imgCroppedChanged', imgCropped);
            const cropper = cropperRef.value;
            if (cropper) {
                const {
                    coordinates,
                    imageSize
                } = cropper;
                if (imageSize.width / imageSize.height > coordinates.width / coordinates.height) {
                    // Determine the position of slider bullet
                    // It's 0 if the stencil has the maximum size and it's 1 if it has the minimum size
                    zoom.value =
                        (cropper.imageSize.height - cropper.coordinates.height) /
                        (cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
                } else {
                    zoom.value =
                        (cropper.imageSize.width - cropper.coordinates.width) /
                        (cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
                }
            }
        }

        const onZoom = (value) => {
            const cropper = cropperRef.value
            if (cropper) {
                if (cropper.imageSize.height < cropper.imageSize.width) {
                    const minHeight = cropper.sizeRestrictions.minHeight;
                    const imageHeight = cropper.imageSize.height;
                    console.log('imageHeight', imageHeight)
                    cropper.zoom(
                        (imageHeight - zoom.value * (imageHeight - minHeight)) /
                        (imageHeight - value * (imageHeight - minHeight))
                    );
                } else {
                    const minWidth = cropper.sizeRestrictions.minWidth;
                    const imageWidth = cropper.imageSize.width;
                    cropper.zoom(
                        (imageWidth - zoom.value * (imageWidth - minWidth)) /
                        (imageWidth - value * (imageWidth - minWidth))
                    );
                }
            }
        };

        return {
            props,
            zoom,
            cropperRef,
            change,
            onZoom
        }
    }
}
</script>
