<template>
<div class="modal fade show d-block" tabindex="-1" role="dialog" raria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="confirmeModalLongTitle">{{ props.modalTitle }}</h5>
            </div>
            <div class="modal-body">
                <draggable tag="ul" :list="imagesData" class="d-flex mt-1 p-3 gap-16px bg-white" handle=".handle" item-key="uuid">
                    <template #item="{ element }">
                        <li class="d-flex flex-column align-items-center gap-8px">
                            <div class="crop-internal" @click="onModal">
                                <img :src="`${props.baseUrl}/v1/public/image-files/${element.uuid}?size=full`" :alt="element.alt" />
                            </div>
                            <div class="drag-img d-flex">
                                <div class="handle"><img src="@/assets/icons/drag.png" /></div>
                            </div>
                        </li>
                    </template>
                </draggable>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="hideModal">Confermare</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import draggable from "vuedraggable";
import {
    closeModal
} from 'jenesius-vue-modal'
import {
    ref,
    watch
} from 'vue';
export default ({
    name: "ImageModal",
    components: {
        draggable
    },
    props: {
        modalTitle: {
            type: String,
        },
        modalImagesData: {
            type: Array
        },
        baseUrl: {
            type: String
        }
    },
    emits: ['ImgDel', 'ModalImgSelect'],
    setup(props, {
        emit
    }) {
        const imagesData = ref(props.modalImagesData);

        const hideModal = () => {
            closeModal()
        }

        const handleModalImgDel = (uuid) => {
            emit('ImgDel', uuid);
        }

        watch(() => props.modalImagesData, newValue => {
            imagesData.value = newValue;
        })

        return {
            props,
            imagesData,
            hideModal,
            handleModalImgDel,
        }
    }
})
</script>

<style>
div.drag-img>div:nth-child(2):hover {
    cursor: grab;
}
</style>
