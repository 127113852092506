<template>
<div v-if="isLoading">
    <Loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" color="#2D489D" loader="dots"></Loading>
</div>
<div v-else>
    <div v-if="pageModal === 'add'">
        <AddOffer :token="props.token" :baseUrl="props.baseUrl" :getGroupset="getGroupset" @handlePageModal="handlePageModal" @handleReload="handleReload" />
    </div>
    <div v-else-if="pageModal === 'edit'">
        <EditOffer :token="props.token" :baseUrl="props.baseUrl" :getGroupset="getGroupset" :editData="editData" @handlePageModal="handlePageModal" @handleReload="handleReload" />
    </div>
    <div v-else class="p-container">
        <div class="d-flex flex-col w-100">
            <div class="d-flex flex-row justify-content-between w-100">
                <h1 class="text-title">Premialità - Offerte</h1>
                <div v-if="activeItem === 'home'" class="ml-auto d-flex flex-row gap-16px">
                    <premialita-button text="Esporta lista" type="regular" class="bg-white" :isDisable="false" @handleClick="handleExportModal" />
                    <premialita-button text="Aggiungi offerta" type="primary" class="bg-primary-custom" :isDisable="false" @handleClick="handleClick" />
                </div>
                <div v-else class="ml-auto d-flex flex-row gap-16px">
                    <premialita-button text="Salva ordinamento" type="primary" class="bg-primary-custom" :isDisable="false" @handleClick="saveOrder" />
                </div>
            </div>
        </div>

        <div>
            <p class="text-overview">{{ published }} percorsi pubblicati su {{ totals }} presenti</p>
        </div>

        <ul class="nav nav-tabs mt-48px">
            <li class="nav-item">
                <a class="nav-link" @click.prevent="setActive('home')" :class="{ active: isActive('home') }" href="#home">Lista offerte</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" @click.prevent="setActive('inevidence')" :class="{ active: isActive('inevidence') }" href="#inevidence">Ordina le offerte in evidenza</a>
            </li>
        </ul>

        <div class="tab-content py-3" id="myTabContent">
            <div class="tab-pane fade" :class="{ 'active show': isActive('home') }" id="home">
                <premialita-table :activitiesData="tableDisplayData === null ? [] : tableDisplayData" :totalLength="totals" @handleEdit="handleEdit" @handleAction="handleAction" @fetchData="tableFetchData" @tableSearch="tableSearch" />
            </div>
            <div class="tab-pane fade" :class="{ 'active show': isActive('inevidence') }" id="inevidence">
                <premialita-evidence-list :baseUrl="props.baseUrl" :evidenceData="evidenceData" />
            </div>
        </div>
    </div>
</div>
<premialita-modal :isOpen="isErrorModal" @modal-close="() => {}" name="warning-modal">
    <template #header>
        <h3>
            Avvertimento!
        </h3>
    </template>
    <template #content>
        <h5>Impossibile recuperare i dati sulle offerte.</h5>
    </template>
    <template #footer>
        <premialita-button text="Indietro" type="regular" class="bg-white" :isDisable="false" @handleClick="warningGoback" />
    </template>
</premialita-modal>
<premialita-modal :isOpen="isCsvModal" @modal-close="onCloseCsvModal" @submit="handleExport" name="csv-modal">
    <template #header>
        <h3>Esporta Lista</h3>
    </template>
    <template #content>
        <Multiselect :searchable="false" :show-labels="false" :allow-empty="false" v-model="exportFormat" :options="csvFormatArray" :hideSelected="true" placeholder="Seleziona il formato di esportazione"></Multiselect>
    </template>
</premialita-modal>
</template>

<script>
import {
    ref,
    toRef,
    onMounted,
} from 'vue'
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
import {
    mkConfig,
    generateCsv,
    download
} from "export-to-csv";
import Multiselect from 'vue-multiselect';
import AddOffer from './addOffer.vue';
import EditOffer from './editOffer.vue';

export default {
    name: 'HomeView',
    components: {
        Loading,
        Multiselect,
        AddOffer,
        EditOffer
    },
    props: {
        baseUrl: {
            type: String,
            required: true
        },
        token: {
            type: String,
            required: true
        },
        router: {
            type: Object
        }
    },
    setup(props) {
        const isLoading = ref(false);
        const isErrorModal = ref(false);
        const pageModal = ref('');
        const getError = ref(null);
        const activeItem = ref('home');
        const getOffers = ref(null);
        const getGroupset = ref(null);
        const tableDisplayData = ref(null);
        const editData = ref(null);
        const evidenceData = ref(null);
        const page = ref(1);
        const published = ref(0);
        const totals = ref(0);
        const isCsvModal = ref(false);
        const exportFormat = ref('CSV');
        const router = toRef(props, 'router');

        const csvFormatArray = ['CSV'];
        let csv;

        const csvConfig = mkConfig({
            useKeysAsHeaders: true,
            filename: "Offers"
        });

        const fetchGroupset = async () => {
            isLoading.value = true;
            try {
                const response = await axios.get(`${props.baseUrl}/v1.1/private/groupsets`, {
                    headers: {
                        Authorization: `Bearer ${props.token}`,
                    },
                });
                getError.value = null;
                getGroupset.value = response.data.results;
                console.log('Group-set-->>>>', response.data);
            } catch (error) {
                getError.value = error;
                getGroupset.value = null;
            } finally {
                isLoading.value = false;
            }
        }

        const fetchOffers = async (page = 0, orderBy = 'name', orderDir = 'asc', name = "") => {
            isLoading.value = true;
            try {
                await fetchGroupset();

                const res = await axios.get(`${props.baseUrl}/v1/private/offers`, {
                    params: {
                        page: page,
                        size: 15,
                        name: name,
                        orderBy: orderBy,
                        orderDir: orderDir
                    },
                    headers: {
                        Authorization: `Bearer ${props.token}`,
                    }
                });

                getError.value = null;

                getOffers.value = [...res.data.results];

                tableDisplayData.value = res.data.results.map(val => ({
                    name: val?.name,
                    groupset: val?.groupset?.name,
                    updatedAt: val?.updatedAt ? val?.updatedAt.split('T')[0] + ' ' + val?.updatedAt.split('T')[1].split('.')[0] : '',
                    status: val?.status?.name,
                    marked: val?.marked ? 'In evidenza' : 'Non in evidenza',
                    ALT: val?.images.length ? val?.images[0].alt : 'image',
                    Avatar: val?.images.length ?
                        `${props.baseUrl}/v1/public/image-files/` + val?.images[0].uuid + '?size=thumb' : '',
                    ID: val?.uuid,
                    points: val?.points
                }));

                totals.value = res.data.count;
                published.value = res.data.results.filter(val => val.status.name === 'PUBLISHED').length;
                evidenceData.value = res.data.results.filter(val => val.marked === true).map(val => ({
                    name: val?.name,
                    groupset: val?.groupset?.name,
                    updatedAt: val?.updatedAt ? val?.updatedAt.split('T')[0] + ' ' + val?.updatedAt.split('T')[1].split('.')[0] : '',
                    status: val?.status?.name,
                    marked: val?.marked ? 'In evidenza' : 'Non in evidenza',
                    ALT: val?.images.length ? val?.images[0].alt : 'image',
                    Avatar: val?.images.length ? val?.images[0].uuid : '',
                    ID: val?.uuid,
                    points: val?.points
                }));

                console.log('Offers-get-->>', res.data, '--->>>', evidenceData.value);
            } catch (error) {
                getError.value = error;
                isErrorModal.value = true;
                getOffers.value = null;
                tableDisplayData.value = null;
                evidenceData.value = null;
                console.log('Offers-err-->>', error);
            } finally {
                isLoading.value = false;
            }
        }

        const handleAction = async (payload) => {
            try {
                await Promise.all(payload.data.map(async val => {
                    await axios.
                    put(
                        `${props.baseUrl}/v1/private/offers/${val.ID}`, {
                            status: getOffers.value.find(item => item.uuid === val.ID).status.name,
                            type: getOffers.value.find(item => item.uuid === val.ID).type.name,
                            name: getOffers.value.find(item => item.uuid === val.ID).name,
                            groupsetId: getOffers.value.find(item => item.uuid === val.ID).groupset.uuid,
                            textFields: [...getOffers.value.find(item => item.uuid === val.ID).textFields].map(item => ({
                                ...item
                            })),
                            images: getOffers.value.find(item => item.uuid === val.ID).images.length ? [...getOffers.value.find(item => item.uuid === val.ID).images].map(item => item.uuid) : [],
                            museums: getOffers.value.find(item => item.uuid === val.ID).museums.length ? [...getOffers.value.find(item => item.uuid === val.ID).museums].map(item => item.uuid) : [],
                            points: getOffers.value.find(item => item.uuid === val.ID).points,
                            discount: getOffers.value.find(item => item.uuid === val.ID).discount,
                            marked: payload.action === 'metti in evidenza' ? true : false,
                            expiresAt: getOffers.value.find(item => item.uuid === val.ID).expiresAt,
                        }, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${props.token}`,
                            },
                        }
                    )
                }))
                handleReload();
            } catch (error) {
                console.log('Action--err->>', error);
            }
        }

        const handleEdit = (payload) => {
            pageModal.value = 'edit';
            editData.value = getOffers.value.find(val => val.uuid === payload.ID);
        }

        const handleExportModal = () => {
            isCsvModal.value = true;
        }

        const onCloseCsvModal = () => {
            isCsvModal.value = false;
        }

        const warningGoback = () => {
            console.log('--Warning--go-->>', router.value)
            if (router.value !== undefined || router.value !== null)
                router.value.go(-1);
        }

        const handleExport = () => {
            if (tableDisplayData.value === null || tableDisplayData.value === undefined) {
                return false;
            } else {
                csv = generateCsv(csvConfig)(tableDisplayData.value);
                download(csvConfig)(csv)
            }
        }

        const handlePageModal = () => {
            pageModal.value = '';
        }

        const saveOrder = () => {

        }

        const handleClick = () => {
            pageModal.value = 'add';
        }

        const setActive = menuItem => {
            activeItem.value = menuItem;
        }

        const isActive = menuItem => {
            return activeItem.value === menuItem;
        }

        const tableFetchData = (payload) => {
            fetchOffers(payload.page - 1, payload.sortBy, payload.sortType)
        }

        const tableSearch = (payload) => {
            fetchOffers(0, 'name', 'asc', payload);
        }

        const handleReload = () => {
            fetchOffers();
        }

        onMounted(() => {
            fetchOffers();
        })

        return {
            props,
            isLoading,
            isErrorModal,
            isCsvModal,
            pageModal,
            getError,
            activeItem,
            getOffers,
            tableDisplayData,
            evidenceData,
            getGroupset,
            csvFormatArray,
            exportFormat,
            page,
            published,
            totals,
            editData,
            handleExportModal,
            handlePageModal,
            saveOrder,
            handleClick,
            setActive,
            isActive,
            handleEdit,
            onCloseCsvModal,
            handleExport,
            warningGoback,
            tableFetchData,
            tableSearch,
            handleReload,
            handleAction
        }
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,700;1,400;1,600&display=swap');

body {
    font-family: 'Titillium Web';
}

.text-title {
    color: var(--neutrali-nero, #1A1A1A);
    font-family: Titillium Web;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -1px;
}

.nav-item .nav-link {
    color: #4c4c4c !important;
}

.nav-item .nav-link.active {
    color: #1A1A1A !important;
}

.modal-body>h5 {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #1A1A1A;
    margin: 0;
}

.p-container {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 70px;
    padding-bottom: 70px;
}

p.text-overview {
    text-align: left;
}

.gap-16px {
    gap: 16px
}

.mt-48px {
    margin-top: 48px;
}
</style>
