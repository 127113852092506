<template>
<div class="offer-point-container d-flex">
    <p>{{ title }}</p>
    <div class="d-flex gap-2 align-items-center">
        <div class="point-icon" @click="decrement" :class="localPointValue <= 1 && 'disabled'">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8" />
            </svg>
        </div>
        <div v-if="type === 'discount'">
            <div v-if="!isEditable" class="fw-bold" @click="makeEditable">{{ localPointValue }}%</div>
            <input v-else type="number" v-model="localPointValue" @blur="updateEditable" class="point-input" ref="input" :min="1" />
        </div>
        <div v-else>
            <div v-if="!isEditable" class="fw-bold" @click="makeEditable">{{ localPointValue }}</div>
            <input v-else type="number" v-model="localPointValue" @blur="updateEditable" class="point-input" ref="input" :min="1" />
        </div>
        <div class="point-icon" @click="increment" :class="localPointValue >= maxLength && 'disabled'">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
            </svg>
        </div>
    </div>
</div>
</template>

    
<script>
export default {
    props: ['title', 'type', 'points', 'maxLength'],
    emits: ['update:points'],
    data() {
        return {
            localPointValue: this.points,
            isEditable: false,
        };
    },
    methods: {
        updateValue() {
            this.$emit('update:points', this.localPointValue);
        },
        updateEditable() {
            this.isEditable = false;
            if (this.localPointValue < 1) {
                this.localPointValue = 1;
            } else if (this.localPointValue > this.maxLength) {
                this.localPointValue = this.maxLength;
            }
        },
        decrement() {
            if (this.localPointValue > 1) {
                this.localPointValue--;
                this.updateValue();
            }
        },
        increment() {
            if (this.localPointValue < this.maxLength) {
                this.localPointValue++;
                this.updateValue();
            }
        },
        makeEditable() {
            this.isEditable = true;
            this.$nextTick(() => {
                this.$refs.input.focus();
            });
        },
    },
    watch: {
        points(newValue) {
            this.localPointValue = newValue;
            this.updateValue();
        },
        localPointValue(newValue) {
            this.$emit('update:points', newValue);
        }
    },
};
</script>

    
<style>
.offer-point-container p {
    width: 75%;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: center;
    margin: 0;
    margin-right: 32px;
}

.point-icon {
    width: 24px;
    height: 24px;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
}

.offer-point-container .point-value {
    width: 60px;
    user-select: none;
    text-align: center;
}

.offer-point-container div.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.point-input {
    width: 60px;
    text-align: center;
    border: none;
    outline: none;
    font-family: 'Titillium Web';
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0px;
    color: #000000;
    background: transparent;
    border-bottom: 1px solid;
    padding: 0;
    margin: 0;
    user-select: none;
}
</style>
